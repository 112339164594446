import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {Box, Paper, Stack, Typography} from '@mui/material';

import {getDashboardAccount, getDashboardCommunity} from '../../../../../app/store/reducers/dashboard';
import {useLazyGetDelinquencyByPortfolioQuery} from '../../../../../features/coreApi/metricsSlice';
import {getIsStakeAdmin, getPropertyPermissions} from '../../../../../app/store/reducers/session';
import {getAccounts, getCommunities} from '../../../../../app/store/reducers/entities';
import {getOpenItem} from '../../../../../app/store/reducers/menu';
import {StyledMarginWidthDividerSm} from '../../../@extended/Divider';
import {getRandomValue} from '../../../../constants/tableColumns';
import {DelinquencyDetailGroup} from './DelinquencyInfo';
import {MetricsHeader} from './MetricsInfo';

import {ComplexLoadingBox} from '../../../@extended/LoadingBox';
import ErrorBox from '../../../@extended/ErrorBox';

const Delinquency = () => {
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const openItem = useSelector(getOpenItem);
  const propertyPermissions = useSelector(getPropertyPermissions)

  const [timeFilter, setTimeFilter] = useState(12);
  const [fetchDelinquencyByPortfolio, {data, isLoading, isFetching, isError, isSuccess}] = useLazyGetDelinquencyByPortfolioQuery()

  useEffect(() => {
    if (dashboardAccount?.sfid && dashboardCommunity?.sfid && timeFilter) {
      fetchDelinquencyByPortfolio({sfid: dashboardAccount.sfid, community: dashboardCommunity.sfid, timeFilter, isStakeAdmin, propertyPermissions});
    }
  }, [dashboardAccount, dashboardCommunity, timeFilter, isStakeAdmin, fetchDelinquencyByPortfolio]);

  const mainComponent = useMemo(() => {
    if (isLoading || isFetching) {
      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <ComplexLoadingBox isLoading={isLoading || isFetching} />
        </Paper>
      );
    } else if (isError) {
      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <ErrorBox />
        </Paper>
      );
    } else if (isSuccess && data?.is_supported) {
      const randomId = getRandomValue();

      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <Stack direction="column" rowGap={2} divider={<StyledMarginWidthDividerSm />}>
            <MetricsHeader
              title={data?.title}
              description={data?.integtration_description}
              timeFilter={timeFilter}
              setTimeFilter={setTimeFilter}
            />
            <DelinquencyDetailGroup key={`${randomId}-group-data`} data={data.data} />
          </Stack>
        </Paper>
      );
    } else if (isSuccess && !data?.is_supported) {
      return (
        <Paper sx={{p: 2, borderRadius: 2, boxShadow: 'none'}}>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{height: '140px'}}>
            <Typography variant="subtitle2" color={'text.subtitle'} fontWeight={'fontWeightRegular'}>
              Delinquency graph data only available for integrated properties
            </Typography>
          </Box>
        </Paper>
      );
    }
  }, [
    isLoading,
    isSuccess,
    isFetching,
    isError,
    timeFilter,
    isStakeAdmin,
    data,
    dashboardAccount,
    dashboardCommunity,
    openItem,
  ]);

  return mainComponent;
};

export default Delinquency;
