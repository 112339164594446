import React, {lazy} from 'react';

// project import
import Loadable from '../commons/components/Loadable';
import HomeTabs from '../commons/components/HomeTabs/HomeTabs';
import ProfilePage from '../pages/Profile/ProfilePage';
import ProfileGeneral from '../commons/components/Profile/General/ProfileGeneral';
import TeamMembers from '../commons/components/Profile/Team/TeamMembers';
import Invoice from '../commons/components/Profile/Invoice/Invoice';
// lazy load
const HomePage = Loadable(lazy(() => import('../pages/Home/HomePage')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = [
  {path: 'account/:account_sfid', element: <HomeTabs />},
  {path: 'account/:account_sfid/settings', element: <ProfilePage />},
  {path: 'account/:account_sfid/settings/general', element: <ProfileGeneral />},
  {path: 'account/:account_sfid/settings/team', element: <TeamMembers />},
  {path: 'account/:account_sfid/settings/invoice', element: <Invoice />},
  {path: 'account/:account_sfid/community/:community_sfid', element: <HomeTabs />},
  {path: 'account/:account_sfid/community/:community_sfid/campaign/:campaign_sfid', element: <HomeTabs />},
  {path: '/', element: <HomePage />}
];

export default MainRoutes;
