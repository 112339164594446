import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const convertToLocalTime = (date, outputFormat = 'YYYY/MM/DD', outputTimezone = null) => {
  try {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(customParseFormat);
    let dayjsObj;

    // Handle different input types
    if (date === null || date === undefined) {
      console.warn('Date input is null or undefined');
      return null;
    } else if (typeof date === 'string') {
      // For strings, explicitly treat as UTC by appending 'Z' if missing
      if (date.includes('T') && !date.endsWith('Z') && !date.includes('+')) {
        dayjsObj = dayjs.utc(date + 'Z');
      } else {
        dayjsObj = dayjs.utc(date);
      }
    } else if (date instanceof Date) {
      // For Date objects, convert to UTC first
      dayjsObj = dayjs.utc(date.toISOString());
    } else if (typeof date === 'number') {
      // Unix timestamps are already UTC
      dayjsObj = dayjs.utc(date);
    } else if (typeof date === 'object') {
      // For date objects with separate parts
      if (date?.date && date?.time) {
        // Combine date and time string and treat as UTC
        let dateString = `${date.date}T${date.time}`;

        // Add seconds if missing from time
        if (date.time.split(':').length < 3) {
          dateString = `${dateString}:00`;
        }

        // Add Z for UTC if missing
        if (!dateString.endsWith('Z')) {
          dateString = `${dateString}Z`;
        }

        dayjsObj = dayjs.utc(dateString);
      } else if (date?.date) {
        dayjsObj = dayjs.utc(date.date);
      } else {
        console.warn(`Invalid date object format - ${JSON.stringify(date)}`);
        return null;
      }
    } else {
      console.warn(`Unsupported date input type: ${typeof date}`);
      return null;
    }

    if (!dayjsObj.isValid()) {
      console.warn(`Invalid date: ${JSON.stringify(date)}`);
      return null;
    }

    // Convert from UTC to target timezone or local
    if (outputTimezone) {
      return dayjsObj.tz(outputTimezone).format(outputFormat);
    } else {
      return dayjsObj.local().format(outputFormat);
    }
  } catch (error) {
    console.warn(`Error converting date: ${error.message}`);
    return null;
  }
};
