import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Divider, Paper, Stack, Typography} from '@mui/material';

import {getOpenItem} from '../../../../../app/store/reducers/menu';
import {CampaignsColumns, getRandomValue, valueGetter} from '../../../../constants/tableColumns';
import {CustomNoRowsCampaignOverlay, ExportToolBarWithSearch} from '../../../@extended/DataGridTables';
import {findActiveOffersChipValue} from './utils';
import {getCommunities} from '../../../../../app/store/reducers/entities';

import TableBodyCell from '../../../@extended/TableBodyCell';
import {StyledTable} from '../../../@extended/CustomTables';
import {getCampaignLoadingStatus} from '../../../../../app/store/reducers/loading';

const CampaignsTable = ({data}) => {
  const navigate = useNavigate();
  const params = useParams();
  const openItem = useSelector(getOpenItem);
  const communities = useSelector(getCommunities);
  const campaignLoadingStatus = useSelector(getCampaignLoadingStatus);

  const columns = useMemo(() => {
    let columns = [...CampaignsColumns];
    if (openItem == 'communities') {
      columns = columns.filter((c) => c.id !== 'community');
    }

    return columns.map((column) => ({
      ...column,
      field: column.id,
      headerName: column.label,
      renderCell: (params) => <TableBodyCell column={column} params={params} />,
      valueFormatter: (params) => valueGetter(params, column),
      renderEditCell: (params) => <TableBodyCell column={column} params={params} />
    }));
  }, [openItem]);

  const rows = useMemo(() => {
    if (data.length > 0) {
      const resRows = data.map((res) => {
        const obj = {
          id: res.id || getRandomValue(),
          name: res.name != null ? res.name : res.subtype
        };

        CampaignsColumns.forEach((column) => {
          const {id} = column;

          if (id == 'community') {
            const community = res?.rental_community || communities.find((c) => c.sfid === res.property_sfid) || {};
            obj[id] = community?.name || '';
            obj.communityData = community || {};
          } else if (id == 'all_actions') {
            if (res['status'] !== 'Active') {
              obj[id] = [];
            } else {
              const actions = res[id] ? res[id].filter((ele) => !ele.id.includes('legacy-action')) : [];
              const offers = findActiveOffersChipValue(actions);
              obj[id] = offers;
            }
          } else if (id == 'target') {
            const targets = res[id]?.unit_types || '';
            let targetsList = targets && !targets.includes('All') ? targets.split(';') : [];
            obj[id] = targetsList;
          } else if (id == 'name') {
            obj[id] = res.name ? res.name : res.subtype;
          } else if (id == 'subtype') {
            obj[id] = res?.display_subtype || res.subtype;
          } else {
            obj[id] = res[id];
          }
        });

        return obj;
      });

      return resRows.sort((a, b) => {
        if (a.community < b.community) return -1;
        if (a.community > b.community) return 1;
        return 0;
      });
    }
    return [];
  }, [data, communities]);

  const handleRowClick = (rowParams) => {
    const path = `/account/${params.account_sfid}/community/${rowParams.row.communityData.sfid}/campaign/${rowParams.row.id}`;
    navigate(path);
  };

  return (
    <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
      <Stack direction={'column'} sx={{width: '100%', height: '100%'}} divider={<Divider />}>
        <Typography variant="h5" fontWeight={'fontWeightMedium'} sx={{p: '16px 24px'}}>
          Campaigns
        </Typography>
        <StyledTable
          rows={rows}
          columns={columns}
          loading={campaignLoadingStatus.isLoading}
          slots={{
            toolbar: ExportToolBarWithSearch,
            noRowsOverlay: CustomNoRowsCampaignOverlay
          }}
          pagination
          pageSizeOptions={[10, 20, 50, 100]}
          initialState={{
            pagination: {paginationModel: {pageSize: 10}}
          }}
          autoHeight
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          rowHeight={60}
          onRowClick={handleRowClick}
          sx={{
            cursor: 'pointer'
          }}
        />
      </Stack>
    </Paper>
  );
};

CampaignsTable.propTypes = {
  data: PropTypes.array
};

export default CampaignsTable;
