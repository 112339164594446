export const sortNumbers = (v1, v2) => {
  if (v1 == null && v2 == null) return 0;
  if (v1 == null) return 1;
  if (v2 == null) return -1;
  // Convert string values to numbers for comparison
  const num1 = typeof v1 === 'string' ? parseFloat(v1) : v1;
  const num2 = typeof v2 === 'string' ? parseFloat(v2) : v2;
  return num1 - num2;
};

export const sortStrings = (v1, v2) => {
  if (v1 == null && v2 == null) return 0;
  if (v1 == null) return 1;
  if (v2 == null) return -1;
  return v1.localeCompare(v2);
};
