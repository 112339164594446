import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {Box, Divider, Paper, Stack, Tab, Typography} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';

import ConcessionGlance from './ConcessionGlance';
import DaysOnMarketGlance from './DaysOnMarketGlance';
import DelinquencyGlance from './DeinquencyGlance';
import {useSelector} from 'react-redux';
import {getIsStakeAdmin, getPropertyPermissions, getTeamMemberRole} from '../../../../../../app/store/reducers/session';
import {getDashboardCommunities} from '../../../../../../app/store/reducers/dashboard';
import VacancyGlance from './VacancyGlance';
import {useParams} from 'react-router-dom';
import {getCommunityLoadingStatus} from '../../../../../../app/store/reducers/loading';
import LoadingBox from '../../../../@extended/LoadingBox';
import ErrorBox from '../../../../@extended/ErrorBox';
import {useGetGlanceDataQuery} from '../../../../../../features/coreApi/metricsSlice';
import ExposureGlance from './ExposureGlance';

const Glance = () => {
    const params = useParams();
    const communitiesLoadingStatus = useSelector(getCommunityLoadingStatus);

    const {data, isError, isSuccess, isLoading, isFetching} = useGetGlanceDataQuery(params?.account_sfid, {
        skip: !params?.account_sfid
    });

    const glanceComponent = useMemo(() => {
        if (isLoading || isFetching || communitiesLoadingStatus.isLoading) {
            return <LoadingBox />;
        } else if (isError || communitiesLoadingStatus.isError) {
            return <ErrorBox />;
        } else if (isSuccess) {
            return <GlanceComponent data={data.data} tabs={data.tabs} />;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isFetching, isError, isSuccess, communitiesLoadingStatus]);

    return glanceComponent;
};

const GlanceComponent = ({data, tabs}) => {
    const params = useParams();
    const [value, setValue] = useState('0');
    const [activeTabs, setActiveTabs] = useState([]);
    const isStakeAdmin = useSelector(getIsStakeAdmin);
    const teamMemberRole = useSelector(getTeamMemberRole);
    const isAdministrator = teamMemberRole === 'Administrator';
    const propertyPermissions = useSelector(getPropertyPermissions);
    const communities = useSelector(getDashboardCommunities);

    const handleChange = (event, newValue) => {
        event.preventDefault();
        event.stopPropagation();

        setValue(newValue);
    };

    const glanceData = useMemo(() => {
        setActiveTabs([]);
        const newActiveTabs = [];

        if (isStakeAdmin || isAdministrator) {
            const newData = {};
            const cSfids = params?.community_sfid ? communities.map((c) => params.community_sfid) : communities.map((c) => c.sfid);
            for (const key in data) {
                const valueList = data[key];
                const filteredData = valueList.filter((value) => cSfids.includes(value.property_sfid));
                if (filteredData.length > 0) {
                    newData[key] = filteredData;
                    if (!newActiveTabs.includes(key)) {
                        newActiveTabs.push(key);
                    }
                }
            }
            setActiveTabs(newActiveTabs);
            return newData;
        } else {
            const newData = {};
            for (const key in data) {
                const valueList = data[key];
                let filteredData = valueList.filter((value) => propertyPermissions.includes(value.property_sfid));
                if (params?.community_sfid) {
                    filteredData = filteredData.filter((value) => value.property_sfid === params.community_sfid);
                }
                if (filteredData.length > 0) {
                    newData[key] = filteredData;
                    if (!newActiveTabs.includes(key)) {
                        newActiveTabs.push(key);
                    }
                }
            }

            setActiveTabs(newActiveTabs);
            return newData;
        }
    }, [data, isStakeAdmin, isAdministrator, propertyPermissions, communities, params]);

    if (!tabs || !tabs.length || activeTabs.length === 0) {
        return null;
    }

    return (
        <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
            <Stack direction={'column'} divider={<Divider />}>
                <Stack direction={'column'} sx={{p: 2}}>
                    <Typography variant="h5" fontWeight="fontWeightMedium">
                        At a glance
                    </Typography>
                    <Typography variant="body2" color={'text.subtitle'}>
                        For last 3 months
                    </Typography>
                </Stack>
                <Box sx={{width: '100%', height: '100%', bgcolor: 'transparent', borderRadius: 2}}>
                    <TabContext value={value}>
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList
                                onChange={handleChange}
                                aria-label="at a glance tabs"
                                indicatorColor="secondary"
                                textColor="secondary"
                                variant="scrollable"
                                scrollButtons="auto"
                                sx={{
                                    '.MuiTab-root': {
                                        fontWeight: 'fontWeightRegular'
                                    }
                                }}
                            >
                                {activeTabs.map((tab, index) => (
                                    <Tab key={tab} label={tab} value={index.toString()} />
                                ))}
                            </TabList>
                        </Box>
                        {activeTabs.map((tab, index) => {
                            let tabComponent = null;

                            switch (tab) {
                                case 'Concession Comps':
                                    tabComponent = <ConcessionGlance data={glanceData['Concession Comps']} />;
                                    break;
                                case 'Historical Days on Market':
                                    tabComponent = <DaysOnMarketGlance data={glanceData['Historical Days on Market']} />;
                                    break;
                                case 'Delinquency':
                                    tabComponent = <DelinquencyGlance data={glanceData['Delinquency']} />;
                                    break;
                                case 'Vacancy':
                                    tabComponent = <VacancyGlance data={glanceData['Vacancy']} />;
                                    break;
                                case 'Exposure':
                                    tabComponent = <ExposureGlance data={glanceData['Exposure']} />;
                                    break;
                                default:
                                    break;
                            }

                            if (!tabComponent) {
                                return null;
                            } else {
                                return (
                                    <TabPanel key={tab} value={index.toString()} sx={{p: '0'}}>
                                        {tabComponent}
                                    </TabPanel>
                                );
                            }
                        })}
                    </TabContext>
                </Box>
            </Stack>
        </Paper>
    );
};

Glance.propTypes = {
    data: PropTypes.object,
    tabs: PropTypes.array
};

export default Glance;
