import React from 'react';
import PropTypes from 'prop-types';

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {fontAwesomeIcons} from '../../utils/icons';
import {Box, Button, InputAdornment, Stack, Typography, useTheme} from '@mui/material';
import {StyledGridOverlay} from './CustomTables';

export const ExportToolBarWithSearch = ({handleAdd, isAddOn, defaultFilename, ...other}) => {
  const theme = useTheme();

  const searchProps = {
    variant: 'outlined',
    size: 'small',
    sx: {
      width: '300px',
      color: theme.palette.grey[600]
    }
  };

  return (
    <GridToolbarContainer
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '16px 24px',
        '& button': {height: '35px', pt: 0, pb: 0}
      }}
    >
      <Stack direction={'row'} spacing={2}>
        <GridToolbarColumnsButton slotProps={{button: {variant: 'outlined', color: 'primary'}}} />
        <GridToolbarFilterButton slotProps={{button: {variant: 'outlined', color: 'primary'}}} />
        <GridToolbarQuickFilter
          {...searchProps}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{paddingLeft: '8px'}}>
                <FontAwesomeIcon icon={fontAwesomeIcons['faMagnifyingGlass']} />
              </InputAdornment>
            )
          }}
        />
      </Stack>
      <Stack direction={'row'} spacing={2}>
        {isAddOn && (
          <Button
            variant="contained"
            color="secondary"
            endIcon={<FontAwesomeIcon icon={fontAwesomeIcons.faCirclePlus} style={{width: '15px', height: '15px'}} />}
            onClick={handleAdd}
          >
            Add
          </Button>
        )}
        <GridToolbarExport
          slotProps={{button: {variant: 'contained', color: 'secondary'}}}
          csvOptions={{
            fileName: defaultFilename || 'Stake_Report',
            delimiter: ',',
            utf8WithBom: true
          }}
        />
      </Stack>
    </GridToolbarContainer>
  );
};

export const ExportButton = ({onClick}) => (
  <Button onClick={onClick} variant="contained">
    Export
  </Button>
);

ExportButton.propTypes = {
  onClick: PropTypes.func
};

export const ExportToolbar = ({defaultFilename = 'Stake_Report'}) => {
  const theme = useTheme();

  return (
    <GridToolbarContainer
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        p: '8px',
        '& button': {height: '30px', pt: 0, pb: 0, border: '1px solid white'}
      }}
    >
      <GridToolbarExport
        slotProps={{button: {variant: 'outlined', color: 'primary'}}}
        csvOptions={{
          fileName: defaultFilename,
          delimiter: ',',
          utf8WithBom: true
        }}
      />
    </GridToolbarContainer>
  );
};

ExportToolbar.propTypes = {
  defaultFilename: PropTypes.string
};

export const ExportToolbarWithTitle = ({title = null, defaultFilename = 'Stake_Report', borderColor = 'white'}) => {
  const theme = useTheme();

  return (
    <GridToolbarContainer
      sx={{
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: '8px 16px',
        '& button': {height: '30px', pt: 0, pb: 0, border: `1px solid ${borderColor}`}
      }}
    >
      <Typography variant="subtitle2" gutterBottom sx={{p: 2, pb: 0, pl: 0}} fontWeight={'fontWeightRegular'}>
        {title}
      </Typography>
      <GridToolbarExport
        slotProps={{button: {variant: 'outlined', color: 'primary'}}}
        csvOptions={{
          fileName: defaultFilename,
          delimiter: ',',
          utf8WithBom: true
        }}
      />
    </GridToolbarContainer>
  );
};

ExportToolbarWithTitle.propTypes = {
  title: PropTypes.string,
  defaultFilename: PropTypes.string,
  borderColor: PropTypes.string
};

export const CustomNoRowsOverlay = ({msg = null}) => {
  return (
    <StyledGridOverlay>
      <Box sx={{mt: 2}}>
        <Typography variant="h6" color="text.subtitle">
          {msg || 'No data found'}
        </Typography>
      </Box>
    </StyledGridOverlay>
  );
};

export const CustomFeedNoRowsOverlay = () => {
  return (
    <StyledGridOverlay>
      <Box sx={{mt: 2}}>
        <Typography variant="h6" color="text.subtitle">
          No recent resident updates.
        </Typography>
        <Typography variant="h6" color="text.subtitle">
          Ask your Stake customer success manager about additional physical marketing materials or digital messaging campaigns to boost
          engagement!
        </Typography>
      </Box>
    </StyledGridOverlay>
  );
};

export const CustomNoRowsCampaignOverlay = () => {
  return (
    <StyledGridOverlay>
      <Box sx={{mt: 2}}>
        <Typography variant="h6" color="text.subtitle">
          Start a campaign to see data
        </Typography>
      </Box>
    </StyledGridOverlay>
  );
};
