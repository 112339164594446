import React from 'react';
import {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Box, Chip, Stack, Typography, useTheme} from '@mui/material';

import {getRandomValue} from '../../../../constants/tableColumns';
import {MetricsGraph} from '../../../@extended/MetricsGraph';
import {MetricsTable} from './MetricsInfo';

const DelinquencySubheader = ({data}) => {
  const theme = useTheme();
  const title = data?.title ?? '';
  const value = data?.value ?? '';
  const textColor = data?.text_color ?? '';
  const color = data?.color ?? '';

  return (
    <Box sx={{width: '100%', borderRadius: '8px', border: `1px solid ${theme.palette.grey[300]}`, p: 1.5}}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight={'fontWeightRegular'}>
          {title}
        </Typography>
        <Chip label={value} sx={{backgroundColor: color, color: textColor, '& .MuiChip-label': {fontSize: 14, fontWeight: 500}}} />
      </Stack>
    </Box>
  );
};

DelinquencySubheader.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string,
    text_color: PropTypes.string,
    value: PropTypes.string
  })
};

export const DelinquencyDetailGroup = ({data, timeFilter}) => {
  const isDataPresent = useMemo(() => {
    let isPresent = false;

    for (const key in data?.general) {
      isPresent = data.general[key].value !== null && data.general[key].value !== undefined && data.general[key].value !== 'No data';
    }

    return isPresent;
  }, [data]);

  const delinquencyGData = useMemo(() => {
    let formattedData = {};

    if (data?.graph_data?.categories) {
      formattedData.categories = data?.graph_data.categories.filter((item) => item !== 'Stake Checking' && item !== 'Paid with SVD');
    }

    if (data?.graph_data?.colors) {
      const colorsObj = data.graph_data.colors;
      formattedData.colors = Object.keys(colorsObj).reduce((acc, key) => {
        if (key !== 'Stake Checking' && key !== 'Paid with SVD') {
          acc[key] = colorsObj[key];
        }
        return acc;
      }, {});
    }

    formattedData.data = data?.graph_data?.data || [];
    return formattedData;
  }, [timeFilter, data?.graph_data]);

  const delinquencyTableData = useMemo(() => {
    let formattedData = {};
    formattedData.columns = data?.table_data?.columns || [];

    if (data?.table_data?.rows) {
      formattedData.rows = data?.table_data.rows.filter((row) => row?.category !== 'Stake Checking' && row?.category !== 'Paid with SVD');
    }

    return formattedData;
  }, [timeFilter, data?.table_data]);

  const delinquencyGeneralData = useMemo(() => data?.general, [timeFilter, data?.general]);

  let subheaders =
    delinquencyGeneralData &&
    // ['non_staker', 'staker', 'stake_checking', 'paid_with_svd', 'reduction'].map((value) => {
    ['non_staker', 'staker', 'reduction'].map((value) => {
      const randomId = getRandomValue();
      return <DelinquencySubheader key={`${randomId}`} data={delinquencyGeneralData[value]} />;
    });

  let mainComponent;

  if (!isDataPresent) {
    mainComponent = (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{height: '140px'}}>
        <Typography variant="subtitle1" color={'text.subtitle'} fontWeight={'fontWeightRegular'}>
          Delinquency graph data only available for integrated properties
        </Typography>
      </Box>
    );
  } else {
    mainComponent = (
      <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
        <Stack direction="row" columnGap={2}>
          {subheaders}
        </Stack>
        <MetricsGraph graphData={delinquencyGData} name="Delinquency rate" />
        <MetricsTable data={delinquencyTableData} />
      </Stack>
    );
  }

  return (
    <Stack direction="column" rowGap={1}>
      {mainComponent}
    </Stack>
  );
};

DelinquencyDetailGroup.propTypes = {
  data: PropTypes.shape({
    graph_data: PropTypes.object,
    table_data: PropTypes.object,
    general: PropTypes.object
  }),
  timeFilter: PropTypes.number
};
