import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import {Box, Button, Dialog, DialogContent, Divider, Paper, Stack, Typography} from '@mui/material';

import {getAccounts, getCampaigns, getCommunities, getResidents} from '../../app/store/reducers/entities';
import {getIsStakeAdmin} from '../../app/store/reducers/session';
import {moneyFormatterDecimal} from '../../commons/components/@extended/FormatDisplay';
import {useGetResidentPaymentsHistoryQuery, useLazyGetUserTokenQuery} from '../../features/coreApi/residentsSlice';
import {ActivityFeed, CommunicationHistory, CurrentResidentOffers} from './ResidentPageInfo';

import {DialogTitleWithCloseIcon} from '../../commons/components/@extended/CustomDialogs';
import {formatOtherOffers} from '../../commons/components/HomeTabs/Today/Campaign/utils';
import EditResidentPage from './EditResidentPage';
import ResidentPaymentHistory from './ResidentPaymentHistory';
import {statusToSentence} from '../../commons/constants/residentsInfoConstants';

const columns = [
  {title: 'Community', key: 'property_name__c'},
  {title: 'Campaign', key: 'campaign'},
  {title: 'Stake Checking', key: 'stake_debit_status__c'},
  {title: 'Is SVD activated ', key: 'is_svd'},
  {title: 'Unit', key: 'apartment_or_unit__c'},
  {title: 'Rent', key: 'rent_amount__c'},
  {title: 'Lease Starts', key: 'lease_start_date__c'},
  {title: 'Lease Ends', key: 'lease_end_date__c'},
  {title: 'Move in date', key: 'move_in_date__c'},
  {title: 'Date Added', key: 'createddate'}
];

const ResidentPage = ({sfid, handleClose, isOpen}) => {
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const residents = useSelector(getResidents);
  const accounts = useSelector(getAccounts);
  const communities = useSelector(getCommunities);
  const campaigns = useSelector(getCampaigns);

  const resident = useMemo(() => {
    if (residents && residents.length > 0) {
      return residents.find((r) => r.sfid === sfid);
    }
  }, [residents]);

  const [isEdit, setEdit] = useState(false);

  const [fetchUserToken, {data: token = {}, isError: isTokenErrored, isLoading: isTokenLoading}] = useLazyGetUserTokenQuery();

  const {
    data: rph = [],
    isSuccess: isRphSuccess,
    isLoading: isRphLoading,
    isFetching: isRphFetching,
    isError: isRphError
  } = useGetResidentPaymentsHistoryQuery(
    {id: resident?.mobile_user_id__c, token: token?.token},
    {skip: !resident?.mobile_user_id__c || isTokenLoading || isTokenErrored || !token?.token}
  );

  const residentOffersRaw = useMemo(() => {
    const formattedOffers = [];
    if (resident?.offers && resident.offers.length > 0) {
      for (const offer of resident.offers) {
        if (offer?.reward_amount > 0) {
          const formattedOffer = formatOtherOffers(offer);
          const obj = {
            reward_amount: `${formattedOffer.offerRewardChipValue}`,
            qualifiers: formattedOffer.offerDeterminersValues,
            created_at: dayjs.utc(offer.created_at)
          };
          formattedOffers.push(obj);
        }
      }
    }

    return formattedOffers.sort((a, b) => b.created_at - a.created_at);
  }, [resident, campaigns]);

  useEffect(() => {
    if (resident) {
      fetchUserToken(resident?.mobile_user_id__c);
    }
  }, [resident, fetchUserToken]);

  const renderColumnGroup = (columns) => {
    const columnCount = columns.length;
    const columnsPerRow = columnCount >= 4 ? 2 : 1;
    const rowCount = Math.ceil(columnCount / columnsPerRow);

    const columnsComponents = columns.map((column) => (
      <Stack key={column.title.toLowerCase().split(' ').join('-')} direction="row" columnGap={1} sx={{width: '100%'}} alignItems={'center'}>
        <Typography variant="body1" fontWeight="fontWeightMedium" sx={{width: '160px', pl: '24px'}}>
          {column.title}:&nbsp;
        </Typography>
        <Typography variant="body1" fontWeight="fontWeightRegular" sx={{width: 'calc(100% - 160px - 24px)', pl: '24px'}}>
          {column.value}
        </Typography>
      </Stack>
    ));

    const rows = [];
    for (let i = 0; i < rowCount; i++) {
      const startIndex = i * columnsPerRow;
      const rowColumns = columnsComponents.slice(startIndex, startIndex + columnsPerRow);
      rows.push(
        <Stack key={`row-${i}`} direction="row" sx={{width: '100%'}} divider={<Divider orientation="vertical" flexItem />}>
          {rowColumns}
        </Stack>
      );
    }

    return (
      <Stack direction="column" columnGap={2} sx={{width: '100%', p: 0}} divider={<Divider />}>
        {rows}
      </Stack>
    );
  };

  const formatColumnValue = useCallback((key, value) => {
    switch (key) {
      case 'createddate':
      case 'lease_start_date__c':
      case 'lease_end_date__c':
      case 'move_in_date__c':
        return dayjs.utc(value).format('DD MMM YYYY');
      case 'rent_amount__c':
        return moneyFormatterDecimal.format(value);
      case 'is_svd':
        return value === 'DebitCardActivated' ? 'Yes' : 'No';
      case 'stake_debit_status__c':
        return statusToSentence(value);
      default:
        return value === 'null' ? 'no data' : value;
    }
  }, []);

  const formattedColumns = useMemo(() => {
    if (!resident) return [];

    const resCampaign = campaigns.find((c) => c.sfid === resident.renter_campaign__c);
    const resInfo = {...resident, is_svd: resident.stake_debit_status__c};

    return columns.map((column) => {
      let value = column.key === 'campaign' ? resCampaign?.name || 'no data' : formatColumnValue(column.key, resInfo[column.key]);

      return {
        ...column,
        value: (
          <Typography variant="h6" color="text.subtitle" sx={{p: '16px 0'}}>
            {value}
          </Typography>
        )
      };
    });
  }, [resident, communities, accounts, campaigns, formatColumnValue]);

  const mainComponent = useMemo(() => {
    let dialogContent;
    let dialogHeader;
    if (!resident) {
      dialogContent = (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px'}}>
          <Typography variant="subtitle1" color={'text.subtitle'} fontWeight={'fontWeightRegular'}>
            Something went wrong. Please come back later.
          </Typography>
        </Box>
      );
    } else {
      let headerBtn = (
        <Button variant="contained" color="secondary" size="small" onClick={() => setEdit(true)} sx={{p: '0px 12px'}}>
          Edit
        </Button>
      );

      if (isEdit) {
        headerBtn = (
          <Button variant="contained" color="secondary" size="small" onClick={() => setEdit(false)} sx={{p: '0px 12px'}}>
            Cancel
          </Button>
        );
      }

      dialogHeader = (
        <Stack direction="row" justifyContent="space-between" alignItems="center" columnGap={2}>
          <Typography variant="h5" fontWeight={'fontWeightMedium'}>
            {resident.name}
          </Typography>
          {headerBtn}
        </Stack>
      );

      if (isEdit) {
        dialogContent = <EditResidentPage isOpen={isEdit} setOpen={setEdit} resident={resident} />;
      } else {
        dialogContent = (
          <Stack direction="column" rowGap={3} sx={{width: '100%'}}>
            <Paper sx={{width: '100%', height: 'fit-content', boxShadow: 'none', p: '0', borderRadius: '8px'}}>
              {renderColumnGroup(formattedColumns)}
            </Paper>
            <CurrentResidentOffers offers={residentOffersRaw} />
            <ResidentPaymentHistory
              startDate={resident.move_in_date__c}
              data={rph}
              isOptedInCreditReporting={resident?.opted_in_to_credit_reporting__c}
              isSuccess={isRphSuccess}
              isLoading={isRphLoading || isRphFetching}
              isError={isRphError}
            />
            {isStakeAdmin && <CommunicationHistory resident={resident} />}
            <ActivityFeed resident={resident} />
          </Stack>
        );
      }

      return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xl">
          <DialogTitleWithCloseIcon onClose={handleClose} sx={{pt: 3}}>
            {dialogHeader || 'Resident Information'}
          </DialogTitleWithCloseIcon>
          <DialogContent dividers>{dialogContent}</DialogContent>
        </Dialog>
      );
    }
  }, [resident, formattedColumns, isEdit, rph, residentOffersRaw]);

  return mainComponent;
};

export default ResidentPage;
