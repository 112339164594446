import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import {LoadingButton} from '@mui/lab';
import {CircularProgress, Stack, Tooltip, Typography} from '@mui/material';

import {useSendOfferConfirmationMutation} from '../../../../../features/coreApi/apiSlice';
import {getEmail} from '../../../../../app/store/reducers/session';

const ConfirmActionButton = ({row}) => {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState();
  const email = useSelector(getEmail);
  const isConfirmed = row?.is_confirmed;
  const confirmedBy = row?.confirmed_by;
  const confirmedDate = row?.confirmed_date;

  const [confirmAction] = useSendOfferConfirmationMutation();

  useEffect(() => {
    if (row?.is_confirmed) {
      setBtnText('Confirmed');
    } else {
      setBtnText('Confirm');
    }
  }, [row]);

  const handleConfirm = async (row) => {
    setLoading(true);
    const offerId = row?.offer_id ? (row.offer_id == 'nan' ? 'N/A' : row?.offer_id) : 'N/A';
    const currentDate = new Date();
    const formattedDate =
      currentDate.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }) +
      ' ' +
      currentDate.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      });

    try {
      await confirmAction({
        account: params?.account_sfid,
        resident: row?.contact_sfid,
        data: {
          offer_id: offerId,
          confirmed_by: email,
          confirmed_date: formattedDate,
          campaign_sfid: row?.campaign_sfid,
          renter_action_id: row?.id
        }
      }).unwrap();
    } catch (error) {
      console.error('Error confirming offer:', error);
    } finally {
      setLoading(false);
    }
  };

  const mainComponent = useMemo(() => {
    if (row?.hide_confirm_button) {
      return <div />;
    } else if (!isConfirmed) {
      return (
        <span>
          <LoadingButton
            loading={isLoading}
            loadingIndicator={<CircularProgress size={16} color="secondary" />}
            variant="contained"
            color="secondary"
            onClick={() => handleConfirm(row)}
            disabled={isConfirmed}
            sx={{
              height: 36.5,
              width: 100,
              backgroundColor: isConfirmed ? '#ccc' : 'secondary.main',
              color: isConfirmed ? '#666' : 'white',
              '&:disabled': {
                backgroundColor: '#ddd',
                color: '#888'
              }
            }}
          >
            {isLoading ? '' : 'Confirm'}
          </LoadingButton>
        </span>
      );
    } else {
      return (
        <Stack direction={'column'}>
          <Typography variant="body2" sx={{fontSize: '13px'}} textAlign={'center'}>
            Confirmed by
          </Typography>
          <Typography variant="body2" sx={{fontSize: '13px'}} textAlign={'center'}>
            {confirmedBy}
          </Typography>
          <Typography variant="body2" sx={{fontSize: '13px'}} textAlign={'center'}>
            {`at ${confirmedDate}`}
          </Typography>
        </Stack>
      );
    }
  }, [isConfirmed, confirmedBy, confirmedDate, isLoading, handleConfirm, row]);

  return (
    <Stack direction="row" alignItems="center" height="100%" width={'100%'} justifyContent={'center'}>
      {mainComponent}
    </Stack>
  );
};

ConfirmActionButton.propTypes = {
  row: PropTypes.object
};

export default ConfirmActionButton;
