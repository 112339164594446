import {loyaltyCloudApi} from './apiSlice';

export const contactListApi = loyaltyCloudApi.injectEndpoints({
    endpoints: (builder) => ({
        getContactLists: builder.query({
            query: (params) => ({
                url: '/contact_lists',
                method: 'get',
                params
            })
        }),

        getContactList: builder.query({
            query: (params) => ({
                url: `/contact_list`,
                method: 'get',
                params
            }),
            providesTags: [{type: 'ContactList', id: 'list'}]
        }),

        createContactList: builder.mutation({
            query: ({data}) => ({
                url: '/contact_list/create',
                method: 'post',
                data: JSON.stringify(data)
            }),
            invalidatesTags: [{type: 'ContactList', id: 'list'}]
        }),

        updateContactListContacts: builder.mutation({
            query: ({id, contact_sfids}) => ({
                url: `/contact_list/${id}`,
                method: 'patch',
                data: JSON.stringify({contact_sfids})
            })
        }),

        deleteContactList: builder.mutation({
            query: (id) => ({
                url: `/contact_list/${id}`,
                method: 'delete'
            })
        })
    })
});

export const {
    useGetContactListsQuery,
    useLazyGetContactListQuery,
    useLazyGetContactListsQuery,
    useGetContactListQuery,
    useCreateContactListMutation,
    useUpdateContactListContactsMutation,
    useDeleteContactListMutation
} = contactListApi;

export const {selectAll: selectAllContactLists, selectById: selectContactListById} = contactListApi.endpoints.getContactLists.select();
