export const ACTIVE_PROPERTY_STATUSES = [
    'Active',
    'Active P1',
    'Active P2',
    'Active P3',
    'At Risk',
    'Test Prop',
    'Due for Renewal',
    'Not Renewing'
];

export const VISIBLE_PROPERTY_STATUSES = [
    'Active',
    'Active P1',
    'Active P2',
    'Active P3',
    'At Risk',
    'Test Prop',
    'Onboarding',
    'Due for Renewal',
    'Not Renewing',
    'Proposal'
];

export const RENEWALS_ACTIVATED_COMMUNITIES = ['a05Pp000005QO0bIAG', 'a054S000000BmlVQAS'];
export const RENEWALS_ACTIVATED_ACCOUNTS = ['0014S000004bx6QQAQ', '0014S000004bYPPQA2', '0014S000004XoR3QAK'];
