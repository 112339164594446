import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import {getOpenItem} from '../../../../app/store/reducers/menu';
import PortfolioTab from './Portfolio/PortfolioTab';
import CommunityTab from './Community/CommunityTab';
import CampaignTab from './Campaign/CampaignTab';

const TodayTab = () => {
    const openItem = useSelector(getOpenItem);

    const activeComponent = useMemo(() => {
        switch (openItem) {
            case 'communities':
                return <CommunityTab />;
            case 'campaigns':
                return <CampaignTab />;
            default:
                return <PortfolioTab />;
        }
    }, [openItem]);

    return (
        <Stack direction="column" rowGap={2} sx={{width: '100%'}}>
            <div>{activeComponent}</div>
        </Stack>
    );
};

export default TodayTab;
