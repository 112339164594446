import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {AlertTitle, Button, Dialog, DialogContent, Stack, Typography, useTheme} from '@mui/material';

import {activeModal, getOpenModal} from '../../../../../app/store/reducers/menu';
import {DialogTitleWithCloseIcon} from '../../../@extended/CustomDialogs';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {FormInputText, FormTextArea} from '../../../@extended/Forms';
import {getEmail} from '../../../../../app/store/reducers/session';
import {getResidents} from '../../../../../app/store/reducers/entities';
import {useSendActionFeedbackMutation} from '../../../../../features/coreApi/apiSlice';
import {useParams} from 'react-router-dom';
import {StyledSuccessAlert} from '../../../@extended/AlertsDialog';

const actionSchema = Yup.object().shape({
  msg: Yup.string()
});

const EditRenterAction = () => {
  const params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const openModal = useSelector(getOpenModal);
  const email = useSelector(getEmail);
  const residents = useSelector(getResidents);
  const [isOpen, setIsOpen] = useState(false);
  const defaultValues = {msg: ''};

  const [sendActionfeedback, {data, isLoading, isSuccess, isError}] = useSendActionFeedbackMutation();

  useEffect(() => {
    if (openModal?.data && openModal?.type === 'edit_feed') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver: yupResolver(actionSchema)
  });

  const {
    handleSubmit,
    formState: {isValid, isDirty},
    reset
  } = methods;

  const onSubmit = async (data) => {
    try {
      let textParams = openModal.data.renter_action.split('<span style="font-weight: 500; color: #149CE0;">');
      textParams = textParams[1].split('</span>');
      textParams = textParams.join('');

      const res = residents.find((r) => r.sfid === openModal.data.contact_sfid);

      const jsonData = {
        msg: data.msg,
        renter_action: textParams,
        contact_sfid: openModal.data.contact_sfid,
        contact_name: res ? res.name : '',
        email: email
      };

      await sendActionfeedback({sfid: params?.account_sfid, data: jsonData}).unwrap();
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (error) {
      // Optionally handle errors here
      console.error('Error sending feedback', error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    dispatch(activeModal({type: null}));
    reset(defaultValues);
  };

  const dialogContent = useMemo(() => {
    if (isOpen && openModal.data) {
      return (
        <Stack direction={'column'} rowGap={3} sx={{width: '100%', paddingTop: '16px'}}>
          <Stack direction={'column'} rowGap={1} sx={{width: '100%'}}>
            <Typography variant="h6">Current action</Typography>
            <div
              dangerouslySetInnerHTML={{__html: openModal.data.renter_action}}
              style={{
                fontStyle: 'italic',
                borderLeft: `2px solid ${theme.palette.grey[400]}`,
                paddingLeft: '16px',
                marginLeft: '0',
                color: '#555'
              }}
            />
          </Stack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={'column'} rowGap={2} sx={{width: '100%'}}>
              <Stack direction={'column'} rowGap={1} sx={{width: '100%'}}>
                <Typography variant="h6">Please list notes or edits here</Typography>
                <FormProvider {...methods}>
                  <FormTextArea name="msg" label="" placeholder="Please provide the correct action here..." />
                </FormProvider>
              </Stack>
              <Stack direction="row" justifyContent={'flex-end'} rowGap={2}>
                <Button type="submit" variant="contained" color="secondary" disabled={!isValid || !isDirty}>
                  Send message
                </Button>
              </Stack>
            </Stack>
          </form>
        </Stack>
      );
    }
  }, [isOpen, openModal, isValid, isDirty, handleSubmit, methods, data, isLoading, isSuccess, isError]);

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogContent dividers>{dialogContent}</DialogContent>
    </Dialog>
  );
};

export default EditRenterAction;
