import React from 'react';

import {Box, Typography} from '@mui/material';

const ErrorBox = ({customText = null}) => {
  return (
    <Box width="100%" height={200} display="flex" justifyContent="center" alignItems="center">
      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
        {customText || 'Something went wrong.'}
      </Typography>
    </Box>
  );
};

export default ErrorBox;
