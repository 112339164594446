import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {Divider, Stack, Typography} from '@mui/material';

import {getRandomValue, GlanceConcessionColumns, valueGetter} from '../../../../../constants/tableColumns';
import {getCommunityLoadingStatus} from '../../../../../../app/store/reducers/loading';
import {CustomNoRowsOverlay, ExportToolBarWithSearch} from '../../../../@extended/DataGridTables';
import {getCommunities} from '../../../../../../app/store/reducers/entities';
import {StyledTable} from '../../../../@extended/CustomTables';

import TableBodyCell from '../../../../@extended/TableBodyCell';

const ConcessionGlance = ({data}) => {
    const communities = useSelector(getCommunities);
    const communitiesLoadingStatus = useSelector(getCommunityLoadingStatus);

    const columns = useMemo(
        () =>
            GlanceConcessionColumns.map((column) => ({
                ...column,
                field: column.id,
                headerName: column.label,
                renderCell: (params) => <TableBodyCell column={column} params={params} />,
                renderEditCell: (params) => <TableBodyCell column={column} params={params} />,
                valueFormatter: (params) => valueGetter(params, column)
            })),
        []
    );

    const performers = useMemo(() => {
        const obj = [];

        for (const d of data) {
            const performerId = getRandomValue();
            const concession = `${d.current_average_market_concession}% of rent`;
            const offer = `${d.average_existing_stake_plus_offer}% of rent`;

            const community = communities.find((c) => c.sfid === d.property_sfid);
            let usState = community?.full_address__statecode__s;

            if (!usState && community?.address__c) {
                usState = community?.address__c.split(' ');
                usState = usState[usState.length - 2];
            }

            const o = {
                ...d,
                id: performerId,
                property_name: community?.name || '',
                current_average_market_concession: concession,
                current_average_stake_plus_offer: offer,
                us_state: usState,
                total_units: d.total_units || community?.total_units__c || 0
            };
            obj.push(o);
        }

        return obj;
    }, [data, communities]);

    const rowsComponent = useMemo(() => {
        if (performers?.length > 0) {
            return (
                <Stack direction={'column'} divider={<Divider />}>
                    <Stack sx={{p: 2}}>
                        <Typography variant={'subtitle2'} fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                            See what your comps are offering as a concession on new leases. All concessions are normalized to be displayed
                            as a percentage of rent.
                        </Typography>
                    </Stack>
                    <StyledTable
                        rows={performers || []}
                        columns={columns}
                        loading={communitiesLoadingStatus.isLoading}
                        pagination
                        pageSizeOptions={[10, 20, 50, 100]}
                        slots={{toolbar: ExportToolBarWithSearch, noRowsOverlay: CustomNoRowsOverlay}}
                        initialState={{
                            pagination: {paginationModel: {pageSize: 10}},
                            sorting: {
                                sortModel: [{field: 'property_name', sort: 'asc'}]
                            }
                        }}
                        autoHeight
                        density="standard"
                        rowHeight={40}
                        disableRowSelectionOnClick
                        getRowId={(row) => row.id}
                    />
                </Stack>
            );
        } else {
            return null;
        }
    }, [performers, columns, communitiesLoadingStatus?.isLoading]);

    return <Stack direction={'column'}>{rowsComponent}</Stack>;
};

ConcessionGlance.propTypes = {
    data: PropTypes.array.isRequired
};

export default ConcessionGlance;
