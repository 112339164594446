import React, {useEffect, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {Stack} from '@mui/material';

import {useLazyGetCampaignByIdQuery} from '../../../../../features/actionsApi/campaignsSlice';
import {useGetContactListsQuery} from '../../../../../features/loyaltyCloudApi/contactListSlice';
import CampaignInfo from './CampaignInfo';
import LoadingBox from '../../../@extended/LoadingBox';
import ErrorBox from '../../../@extended/ErrorBox';
import OffersMainComponent from '../../../Offers/OffersMainComponent';
import FeedComponent from '../Feed/FeedComponent';

const CampaignTab = () => {
  const params = useParams();
  const [fetchCampaign, {data: campaign = {}, isSuccess, isLoading, isFetching, isError}] = useLazyGetCampaignByIdQuery();
  const {data} = useGetContactListsQuery({campaign_sfid: campaign?.id}, {skip: !campaign?.id || campaign?.subtype != 'Renewals'});

  useEffect(() => {
    if (params?.campaign_sfid) {
      fetchCampaign(params.campaign_sfid);
    }
  }, [params?.campaign_sfid, fetchCampaign]);

  const isNotStake1 = useMemo(
    () => campaign && campaign.subtype && !campaign.subtype.toLowerCase().includes('stake1'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, isLoading, isFetching]
  );

  if (isLoading || isFetching) {
    return <LoadingBox />;
  } else if (isError) {
    return <ErrorBox />;
  } else if (isSuccess) {
    return (
      <Stack direction="column" rowGap={3}>
        <CampaignInfo campaign={campaign} />
        <FeedComponent />
        {isNotStake1 && <OffersMainComponent campaign={campaign} />}
      </Stack>
    );
  }
};

export default CampaignTab;
