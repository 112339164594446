import React, {memo, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Controller, useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Checkbox, Chip, FormControlLabel, InputAdornment, MenuItem, Select, Stack, TextField, Typography} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateRangePicker} from '@mui/x-date-pickers-pro';

import {fontAwesomeIcons} from '../../utils/icons';
import {extractCityAndState, extractZipcode} from '../../constants/dateConstants';

export const FormTextArea = ({name, label = null, placeholder = null}) => {
    const {control} = useFormContext();

    return (
        <>
            <Controller
                defaultValue=""
                name={name}
                control={control}
                render={({field, fieldState}) => {
                    return (
                        <TextField
                            {...field}
                            fullWidth
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            label={label}
                            placeholder={placeholder}
                            sx={{
                                width: '100%',
                                height: 140,
                                ['& fieldset']: {
                                    borderRadius: 1
                                }
                            }}
                            multiline
                            rows={5}
                        />
                    );
                }}
            />
        </>
    );
};

FormTextArea.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

export const FormAmount = ({name, label = null, placeholder = null}) => {
    const {control} = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({field, fieldState}) => (
                    <TextField
                        {...field}
                        fullWidth
                        label={label || 'Amount'}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ' '}
                        type="number"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>
                        }}
                        sx={{
                            width: '100%',
                            '& input': {
                                pr: 0
                            },
                            ['& fieldset']: {
                                borderRadius: '5px'
                            },
                            '& .MuiOutlinedInput-root': {
                                height: 44,
                                '& fieldset': {
                                    borderRadius: '4px',
                                    borderColor: '#2c2c2c'
                                }
                            }
                        }}
                    />
                )}
            />
        </>
    );
};

FormAmount.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

export const FormNumber = ({name, label = null, placeholder = null}) => {
    const {control} = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({field, fieldState}) => (
                    <TextField
                        {...field}
                        fullWidth
                        label={label || 'Number'}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ' '}
                        type="number"
                        InputLabelProps={{
                            sx: {
                                overflow: 'visible',
                                whiteSpace: 'nowrap'
                            }
                        }}
                        sx={{
                            width: '100%',
                            '& input': {
                                pr: 0
                            },
                            ['& fieldset']: {
                                borderRadius: '5px'
                            },
                            '& .MuiOutlinedInput-root': {
                                height: 44,
                                '& fieldset': {
                                    borderRadius: '4px',
                                    borderColor: '#2c2c2c'
                                }
                            }
                        }}
                    />
                )}
            />
        </>
    );
};

FormNumber.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

export const FormAmountPercent = ({name, label = null, placeholder = null}) => {
    const {control} = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({field, fieldState}) => (
                    <TextField
                        {...field}
                        fullWidth
                        label={label || 'Percent'}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ' '}
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        sx={{
                            width: '100%',
                            '& input': {
                                pr: 0
                            },
                            ['& fieldset']: {
                                borderRadius: '5px'
                            },
                            '& .MuiOutlinedInput-root': {
                                height: 44,
                                '& fieldset': {
                                    borderRadius: '4px',
                                    borderColor: '#2c2c2c'
                                }
                            }
                        }}
                    />
                )}
            />
        </>
    );
};

FormAmountPercent.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

export const FormInput = ({name, label = null, placeholder = null}) => {
    const {control} = useFormContext();

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                render={({field, fieldState}) => (
                    <TextField
                        {...field}
                        fullWidth
                        label={label}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message || ' '}
                        InputLabelProps={{
                            sx: {
                                overflow: 'visible',
                                whiteSpace: 'nowrap'
                            }
                        }}
                        sx={{
                            width: '100%',
                            '& input': {
                                pr: 0
                            },
                            ['& fieldset']: {
                                borderRadius: '5px'
                            },
                            '& .MuiOutlinedInput-root': {
                                height: 44,
                                '& fieldset': {
                                    borderRadius: '4px',
                                    borderColor: '#2c2c2c'
                                }
                            }
                        }}
                    />
                )}
            />
        </>
    );
};

FormInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};

export const SimpleFormSelectWithLabel = ({name, options}) => {
    const {control} = useFormContext();

    return (
        <>
            <Controller
                defaultValue="default"
                name={name}
                control={control}
                render={({field}) => (
                    <TextField
                        {...field}
                        select
                        fullWidth
                        label={options[0].name}
                        InputProps={{
                            endAdornment: <FontAwesomeIcon icon={fontAwesomeIcons['faChevronDown']} />
                        }}
                        SelectProps={{
                            IconComponent: ''
                        }}
                        InputLabelProps={{
                            style: {
                                top: '-4px',
                                whiteSpace: 'nowrap',
                                overflow: 'visible'
                            }
                        }}
                        sx={{
                            '& .MuiSelect-icon': {
                                right: '14px'
                            },
                            '& .MuiOutlinedInput-root': {
                                height: 44,
                                '& fieldset': {
                                    borderRadius: '4px',
                                    borderColor: '#2c2c2c'
                                }
                            }
                        }}
                    >
                        {options.map((ele, idx) => {
                            if (idx == 0) {
                                return (
                                    <MenuItem key={`select-${ele.id}-${idx}`} value={ele.id} disabled>
                                        {ele.name}
                                    </MenuItem>
                                );
                            } else {
                                return (
                                    <MenuItem key={`select-${ele.id}-${idx}`} value={ele.id}>
                                        {ele.name}
                                    </MenuItem>
                                );
                            }
                        })}
                    </TextField>
                )}
            />
        </>
    );
};

SimpleFormSelectWithLabel.propTypes = {
    name: PropTypes.string,
    options: PropTypes.array
};

export const ChipFieldArray = memo(({name, availableProperties}) => {
    const {control} = useFormContext();
    const {fields, remove, append} = useFieldArray({
        control,
        name: name
    });

    const currentPermissions = useWatch({
        control,
        name: name
    });

    const [selectedProperty, setSelectedProperty] = React.useState('');

    const handleDelete = React.useCallback(
        (index) => {
            remove(index);
        },
        [remove]
    );

    const handleAdd = React.useCallback(() => {
        if (selectedProperty) {
            if (selectedProperty === 'all') {
                const remProperties = availableProperties.filter((prop) => !currentPermissions.some((field) => field.id === prop.id));
                remProperties.forEach((prop) => append(prop));
            } else {
                const newProperty = availableProperties.find((prop) => prop.id === selectedProperty);
                append(newProperty);
            }
            setSelectedProperty('');
        }
    }, [selectedProperty, availableProperties, append]);

    const remainingProperties = useMemo(() => {
        return availableProperties.filter((prop) => !currentPermissions.some((field) => field.id === prop.id));
    }, [availableProperties, currentPermissions]);

    return (
        <Stack direction="column" spacing={2}>
            <Stack direction="row" sx={{flexWrap: 'wrap'}} rowGap={1} columnGap={1}>
                {fields.map((field, index) => (
                    <Chip
                        key={field.id}
                        variant="outlined"
                        color="primary"
                        label={field.name}
                        onDelete={() => handleDelete(index)}
                        deleteIcon={
                            <FontAwesomeIcon
                                icon={fontAwesomeIcons.faXmark}
                                style={{height: '15px', paddingRight: '4px', color: '#3F51B5'}}
                            />
                        }
                    />
                ))}
            </Stack>
            <Stack direction="row" spacing={1} sx={{width: '30%'}}>
                <Select
                    value={selectedProperty}
                    onChange={(e) => setSelectedProperty(e.target.value)}
                    displayEmpty
                    fullWidth
                    disabled={remainingProperties.length === 0}
                    size="small"
                >
                    <MenuItem value="" disabled>
                        {remainingProperties.length === 0 ? 'No more properties available' : 'Add a property permission'}
                    </MenuItem>
                    <MenuItem value="all">All remaining properties</MenuItem>
                    {remainingProperties.map((property) => (
                        <MenuItem key={property.id} value={property.id}>
                            {property.name}
                        </MenuItem>
                    ))}
                </Select>
                <Button onClick={handleAdd} color="secondary" variant="contained" disabled={!selectedProperty}>
                    Add
                </Button>
            </Stack>
        </Stack>
    );
});

ChipFieldArray.propTypes = {
    name: PropTypes.string,
    availableProperties: PropTypes.array
};

export const SimpleFormCheckbox = ({name, label = ''}) => {
    const {control} = useFormContext();

    return (
        <>
            <FormControlLabel
                control={
                    <Controller
                        defaultValue={false}
                        name={name}
                        control={control}
                        render={({field: props}) => {
                            return <Checkbox {...props} checked={props.value} color="secondary" />;
                        }}
                    />
                }
                label={label}
            />
        </>
    );
};

SimpleFormCheckbox.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string
};

export const FormRangePicker = ({name, startText = 'Start date', endText = 'End date', dateSeparator = ''}) => {
    const {control} = useFormContext();
    const [errors, setErrors] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        const isErrored = errors && errors.some((err) => err !== null && err !== undefined);

        if (isErrored) {
            setErrorMsg('Invalid date');
        } else {
            setErrorMsg('');
        }
    }, [errors]);

    return (
        <Stack direction="column" rowGap={2} sx={{width: '100%', pb: '16px'}}>
            <Controller
                name={name}
                control={control}
                rules={{required: 'This field is required'}}
                render={({field}) => {
                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateRangePicker
                                value={field.value || [null, null]}
                                slotProps={{field: {dateSeparator: dateSeparator || ''}}}
                                localeText={{start: startText, end: endText}}
                                onError={(err) => setErrors(err)}
                                onChange={(newValue) => {
                                    setErrors([]);
                                    field.onChange(newValue);
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        height: 44,
                                        '& fieldset': {
                                            borderRadius: '4px',
                                            borderColor: '#2c2c2c'
                                        }
                                    },
                                    '& .MuiTextField-root': {
                                        marginLeft: '0 !important'
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    );
                }}
            />
            {errorMsg && (
                <Typography variant="subtitle2" color="error">
                    {errorMsg}
                </Typography>
            )}
        </Stack>
    );
};

FormRangePicker.propTypes = {
    name: PropTypes.string,
    startText: PropTypes.string,
    endText: PropTypes.string
};

export const GoogleAddressInputField = ({name, label, required = false}) => {
    const [address, setAddress] = useState('');
    const [errorText, setErrorText] = useState('');
    const [hasSearched, setHasSearched] = useState(false);

    const {
        setValue,
        register,
        unregister,
        trigger,
        formState: {errors},
        clearErrors
    } = useFormContext();

    // Register the field with React Hook Form
    useEffect(() => {
        register(name, {
            required: required ? 'Address is required' : false,
            validate: (value) => {
                return (value && value.trim() !== '') || 'Address is required';
            }
        });

        // Cleanup on unmount
        return () => {
            unregister(name);
        };
    }, [name, register, unregister, required]);

    const resetValues = () => {
        setValue(name, '', {shouldValidate: true});
        setValue('zipcode', '', {shouldValidate: true});
        setValue('city', '', {shouldValidate: true});
        setValue('state', '', {shouldValidate: true});
    };

    const setAddressValues = ({city, state, zipcode}) => {
        setValue('city', city, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setValue('state', state, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
        setValue('zipcode', zipcode, {shouldValidate: true, shouldDirty: true, shouldTouch: true});
    };

    const handleChange = (value) => {
        setAddress(value);
        setErrorText('');
        setHasSearched(false);

        // When address is changed, update the form
        setValue(name, value, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true
        });

        // If field is cleared, clear any related fields too
        if (!value || value.trim() === '') {
            resetValues();
        } else {
            const zipcode = extractZipcode(value);
            const {city, state} = extractCityAndState(value);
            setAddressValues({city, state, zipcode});
        }

        // Trigger validation
        clearErrors([name, 'zipcode', 'city', 'state']);
        trigger([name, 'zipcode', 'city', 'state']);
    };

    const assignValues = (results) => {
        const result = results[0].address_components;
        const obj = {
            address: {
                value: '',
                error: null
            },
            zipcode: {
                value: '',
                error: null
            },
            city: {
                value: '',
                error: null
            },
            state: {
                value: '',
                error: null
            }
        };

        for (const r of result) {
            if (r.types.includes('postal_code')) {
                obj.zipcode.value = r.long_name;
            } else if (r.types.includes('locality')) {
                obj.city.value = r.long_name;
            } else if (r.types.includes('administrative_area_level_1')) {
                obj.state.value = r.short_name;
            }
        }
        obj.address.value = results[0].formatted_address;
        setAddress(obj.address.value);

        setValue(name, obj.address.value, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true
        });
        setAddressValues({city: obj.city.value, state: obj.state.value, zipcode: obj.zipcode.value});
        setErrorText('');

        // Validate the form
        clearErrors([name, 'zipcode', 'city', 'state']);
        trigger([name, 'zipcode', 'city', 'state']);
    };

    const handleSelect = (value) => {
        setHasSearched(true);

        if (!value || value.trim() === '') {
            setErrorText('Please enter an address');
            resetValues();
            return;
        }

        geocodeByAddress(value)
            .then((results) => {
                if (results.length === 0) {
                    setErrorText('No address found. Please try a different search.');
                    resetValues();
                    return;
                }

                assignValues(results);
            })
            .catch(() => {
                setErrorText('Unable to find address. Please try again.');
                resetValues();
            });
    };

    const fieldError = errors[name]?.message;

    const handleSearchPress = () => {
        setHasSearched(true);
        if (address.trim() === '') {
            setErrorText('Please enter an address to search');
            resetValues();

            return;
        }

        geocodeByAddress(address)
            .then((results) => {
                if (results.length === 0) {
                    setErrorText('No address found. Please try a different search.');
                    resetValues();
                    return;
                }

                assignValues(results);
            })
            .catch(() => {
                setErrorText('Unable to find address. Please try again.');
                resetValues();
            });
    };

    return (
        <PlacesAutocomplete key={'address-input'} value={address} onChange={handleChange} onSelect={handleSelect}>
            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                <div style={{width: '100%', position: 'relative'}}>
                    <TextField
                        label={label}
                        error={!!fieldError || !!errorText}
                        helperText={fieldError || errorText}
                        sx={{
                            width: '100%',
                            '& .MuiSelect-icon': {
                                right: '14px'
                            },
                            '& .MuiOutlinedInput-root': {
                                height: 44,
                                '& fieldset': {
                                    borderRadius: '4px',
                                    borderColor: '#2c2c2c'
                                }
                            }
                        }}
                        {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input',
                            onKeyDown: (e) => {
                                if (e.key === 'Enter' || e.key === 'Tab') {
                                    handleSearchPress();
                                }
                            }
                        })}
                    />
                    <div
                        className="autocomplete-dropdown-container"
                        style={{
                            position: 'absolute',
                            zIndex: 1000,
                            width: '100%',
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            borderRadius: '4px',
                            maxHeight: '300px',
                            overflowY: 'auto',
                            padding: suggestions?.length > 0 ? '16px' : 0,
                            display: suggestions.length > 0 || loading ? 'block' : 'none'
                        }}
                    >
                        {loading && <div>Loading...</div>}
                        {suggestions.length === 0 && hasSearched && !loading && (
                            <div style={{padding: '10px', color: 'red'}}>No results found. Please try a different search.</div>
                        )}
                        {suggestions.map((suggestion) => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                : {backgroundColor: '#ffffff', cursor: 'pointer'};
                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                    })}
                                    key={suggestion.placeId}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};
