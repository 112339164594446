import React, {Fragment, useState} from 'react';
import {Box, Button} from '@mui/material';
import NewCommunity from '../../../../commons/components/modals/NewCommunity/NewCommunity';

const AddCommunity = () => {
    const [isOpen, setOpen] = useState(false);

    const handleToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setOpen(!isOpen);
    };

    return (
        <Fragment>
            <Box sx={{flexShrink: 0, pt: '6px'}}>
                <Button variant="outlined" onClick={handleToggle} sx={{height: '25px'}}>
                    Add a community
                </Button>
            </Box>
            <NewCommunity isOpen={isOpen} setOpen={setOpen} />
        </Fragment>
    );
};

export default AddCommunity;
