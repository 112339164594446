import {
    faChartLine,
    faArrowDownArrowUp,
    faCirclePlus,
    faXmark,
    faCircleCheck,
    faCircle,
    faCalendar,
    faArrowRightLong,
    faEye,
    faEyeSlash,
    faHouse,
    faTrashCan,
    faPenToSquare,
    faChevronUp,
    faChevronDown,
    faCircleArrowRight,
    faCircleArrowDown,
    faFaceTongueMoney,
    faFaceLaughBeam,
    faFaceLaughSquint,
    faFaceGrin,
    faFaceGrinStars,
    faCircleDollar,
    faChartSimple,
    faUsers,
    faBolt,
    faMoneyCheck,
    faStar as faStarThin,
    faCircleQuestion as faCircleQuestionLight,
    faShare,
    faFaceSunglasses,
    faMoneyBillTrendUp,
    faCalendarClock,
    faBuildingColumns,
    faFileExport,
    faCircleInfo,
    faArrowDown as faArrowDownLight,
    faArrowUp as faArrowUpLight,
    faMagnifyingGlass,
    faShield,
    faChartNetwork,
    faPersonShelter as faPersonShelterThin,
    faWatch,
    faBadgeDollar as faBadgeDollarThin,
    faThoughtBubble,
    faArrowsRotate as faArrowsRotateThin,
    faGear,
    faBell,
    faComment,
    faAngleRight,
    faAngleLeft,
    faFileInvoice,
    faThumbsUp,
    faThumbsDown,
    faInbox,
    faMobileNotch,
    faMessage,
    faCheck,
    faSquarePlus
} from '@fortawesome/pro-light-svg-icons';

import {
    faStar,
    faCircle as faCircleSolid,
    faArrowDown,
    faArrowUp,
    faGreaterThan,
    faCaretDown,
    faCaretUp,
    faAngleRight as faAngleRightSolid
} from '@fortawesome/pro-solid-svg-icons';

import {
    faBuilding,
    faHouseTree,
    faHouseChimney,
    faHeadSideHeart,
    faUserPlus,
    faLayerPlus,
    faDumbbell,
    faCalendarCheck,
    faCalendarLinesPen,
    faCircleQuestion,
    faPersonShelter,
    faMoneyCheckDollarPen,
    faHandPointUp,
    faArrowsRotate,
    faMemoCircleCheck,
    faBadgeDollar,
    faCircleXmark,
    faArrowLeft,
    faArrowRight,
    faTriangleExclamation
} from '@fortawesome/pro-thin-svg-icons';

import {faAngleRight as faAngleRightRegular, faCircleExclamation} from '@fortawesome/pro-regular-svg-icons';

export const fontAwesomeIcons = {
    faChartLine,
    faArrowDownArrowUp,
    faCirclePlus,
    faXmark,
    faCircle,
    faCircleCheck,
    faArrowRightLong,
    faBuilding,
    faHouseTree,
    faHouseChimney,
    faCalendarClock,
    faHeadSideHeart,
    faMoneyBillTrendUp,
    faUserPlus,
    faLayerPlus,
    faSquarePlus,
    faDumbbell,
    faCalendarCheck,
    faCalendarLinesPen,
    faBuildingColumns,
    faFaceSunglasses,
    faEye,
    faEyeSlash,
    faCircleQuestion,
    faPersonShelter,
    faMoneyCheckDollarPen,
    faHandPointUp,
    faMemoCircleCheck,
    faBadgeDollar,
    faHouse,
    faTrashCan,
    faPenToSquare,
    faCircleXmark,
    faCalendar,
    faChevronUp,
    faChevronDown,
    faCircleArrowRight,
    faStar,
    faStarThin,
    faCircleSolid,
    faCircleArrowDown,
    faFaceTongueMoney,
    faFaceLaughBeam,
    faFaceLaughSquint,
    faFaceGrin,
    faFaceGrinStars,
    faCircleDollar,
    faChartSimple,
    faUsers,
    faBolt,
    faMoneyCheck,
    faCircleQuestionLight,
    faShare,
    faFileExport,
    faCircleInfo,
    faArrowDown,
    faArrowUp,
    faArrowUpLight,
    faArrowDownLight,
    faMagnifyingGlass,
    faShield,
    faChartNetwork,
    faGreaterThan,
    faWatch,
    faPersonShelterThin,
    faBadgeDollarThin,
    faCaretDown,
    faThoughtBubble,
    faArrowsRotate,
    faArrowsRotateThin,
    faGear,
    faBell,
    faComment,
    faCaretUp,
    faAngleRight,
    faAngleRightSolid,
    faAngleRightRegular,
    faFileInvoice,
    faArrowLeft,
    faArrowRight,
    faAngleLeft,
    faThumbsUp,
    faThumbsDown,
    faInbox,
    faMobileNotch,
    faMessage,
    faCheck,
    faTriangleExclamation,
    faCircleExclamation
};
