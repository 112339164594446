import React, {useRef} from 'react';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';

// material-ui
import {Box} from '@mui/material';

import Header from './Header/Header';

import {getCookie} from '../../commons/utils/cookies';
import {activeComponent, activeItem, endComponent} from '../../app/store/reducers/menu';
import {getAccountSfid, getAuthorizedStatus} from '../../app/store/reducers/session';
import {findUserAccountsPermission} from '../../commons/components/MenuDashboard/utils';
import {useGetResidentsByAccountSfidQuery} from '../../features/coreApi/residentsSlice';
import {getCampaigns, getCommunities, setAccounts, setCampaigns, setCommunities, setResidents} from '../../app/store/reducers/entities';
import {headerHeight, mainContentPaddingHeight, mainContentPaddingWidth} from '../../config';
import {getDashboardAccount, setCampaignsSelected, setCommunitiesSelected, setDashboard} from '../../app/store/reducers/dashboard';
import AlertsDialog from '../../commons/components/@extended/AlertsDialog';
import ActiveModal from '../../commons/components/modals/activeModal';
import {useLazyGetTeamMemberInfoQuery} from '../../features/loyaltyCloudApi/apiSlice';
import {useLazyGetAccountsQuery} from '../../features/coreApi/apiSlice';
import {useLazyGetCommunitiesByAccountSfidQuery} from '../../features/coreApi/communitiesSlice';
import {useLazyGetCampaignsByAccountSfidQuery} from '../../features/actionsApi/campaignsSlice';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isAuthorized = useSelector(getAuthorizedStatus);
  const sessionToken = getCookie('session_token');
  const entitiesCampaigns = useSelector(getCampaigns);
  const entitiesCommunities = useSelector(getCommunities);
  const sessionAccountSfid = useSelector(getAccountSfid);
  const dashboardAccount = useSelector(getDashboardAccount);

  const [updatedAccountsPermissions, setUpdatedAccountsPermissions] = useState();

  const [fetchCampaigns, {data: camResponse = {}, isSuccess: isCamSuccess, isLoading: isCamLoading, isFetching: isCamFetching}] =
    useLazyGetCampaignsByAccountSfidQuery();
  const [fetchAccounts, {data: acResponse = [], isSuccess: isAcSuccess, isLoading: isAcLoading, isFetching: isAcFetching}] =
    useLazyGetAccountsQuery();
  const [fetchTeamMemberInfo, {data: teamMember, isSuccess: isTMSuccess, isFetching: isTMFetching, isLoading: isTMLoading}] =
    useLazyGetTeamMemberInfoQuery();
  const [
    fetchCommunities,
    {data: comResponse = [], isSuccess: isComSuccess, isLoading: isComLoading, isFetching: isComFetching, isError: isComError}
  ] = useLazyGetCommunitiesByAccountSfidQuery();

  useEffect(() => {
    if (params?.account_sfid) {
      fetchCampaigns(params.account_sfid);
    }
  }, [params?.account_sfid, fetchCampaigns]);

  useEffect(() => {
    if (params?.account_sfid) {
      fetchCommunities(params.account_sfid);
    }
  }, [params?.account_sfid, fetchCommunities]);

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    const fetchData = async (retries = 3) => {
      try {
        if (sessionToken) {
          await fetchTeamMemberInfo(sessionToken);
        }
      } catch (error) {
        console.error('Error fetching data in session info');
        if (retries > 0) {
          fetchData(retries - 1);
        }
      }
    };

    fetchData();
  }, [sessionToken, fetchTeamMemberInfo]);

  const {
    data: resResponse = [],
    isSuccess: isResSuccess,
    isLoading: isResLoading,
    isFetching: isResFetching
  } = useGetResidentsByAccountSfidQuery(params?.account_sfid, {skip: !params?.account_sfid});

  const associatedSfid = useMemo(() => {
    if (sessionAccountSfid) {
      return sessionAccountSfid;
    } else if (teamMember?.associated_sfid) {
      return teamMember.associated_sfid;
    }
  }, [sessionAccountSfid, teamMember?.associated_sfid]);

  const isAdministrator = useMemo(() => {
    if (teamMember?.role === 'Administrator' || teamMember?.property_permissions?.length == 0) {
      return true;
    } else if (teamMember?.property_permissions?.length > 0) {
      return teamMember?.property_permissions.includes('all') || teamMember?.property_permissions.includes('blank');
    }
  }, [teamMember?.role, teamMember?.property_permissions]);

  useEffect(() => {
    if (params?.account_sfid) {
      localStorage.setItem('account_sfid', params.account_sfid);
    }
  }, [params]);

  useEffect(() => {
    let dashboardInfo = {sfid: null, name: null};

    if (acResponse.length > 0 && params?.account_sfid) {
      const selectedAccount = acResponse.find((account) => account.sfid === params.account_sfid);
      dashboardInfo = {...selectedAccount};

      dispatch(setDashboard({type: 'portfolio', data: dashboardInfo}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acResponse, params?.account_sfid]);

  useEffect(() => {
    const path = location.pathname;
    const componentMapping = ['invoice', 'team', 'general', 'settings'];
    const matchedComponent = componentMapping.find((component) => path.includes(component));

    if (matchedComponent) {
      dispatch(activeComponent(matchedComponent));
      dispatch(endComponent(matchedComponent));
    } else {
      if (path.includes('campaign')) {
        dispatch(activeItem('campaigns'));
      } else if (path.includes('community')) {
        dispatch(activeItem('communities'));
      } else {
        dispatch(activeItem('portfolio'));
      }

      dispatch(endComponent('campaigns'));
      dispatch(activeComponent('home'));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isAcSuccess && !isAcFetching && !isAcLoading && isTMSuccess && !isTMLoading && !isTMFetching) {
      const perms = findUserAccountsPermission(teamMember?.master, associatedSfid, teamMember?.additional_accounts_permissions, acResponse);
      setUpdatedAccountsPermissions(perms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    teamMember?.master,
    params?.account_sfid,
    teamMember?.additional_accounts_permissions,
    isAcFetching,
    isAcLoading,
    isAcSuccess,
    isTMSuccess,
    isTMLoading,
    isTMFetching
  ]);

  useEffect(() => {
    if (updatedAccountsPermissions?.length > 0 && !updatedAccountsPermissions.includes(params?.account_sfid)) {
      navigate(`account/${updatedAccountsPermissions[0]}`);
    }
  }, [params, updatedAccountsPermissions, navigate]);

  useEffect(() => {
    if (updatedAccountsPermissions) {
      const selectedAccounts = acResponse.filter((acc) => updatedAccountsPermissions.includes(acc.sfid));
      dispatch(setAccounts(selectedAccounts));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedAccountsPermissions, acResponse]);

  useEffect(() => {
    if (!isComFetching && !isComLoading && isComSuccess) {
      if (teamMember?.master || (updatedAccountsPermissions && updatedAccountsPermissions.length > 1)) {
        dispatch(setCommunities(comResponse));
        dispatch(setCommunitiesSelected(comResponse));
      } else {
        if (isAdministrator) {
          dispatch(setCommunities(comResponse));
          dispatch(setCommunitiesSelected(comResponse));
        } else {
          const propertyPermissions = teamMember?.property_permissions || [];
          const filteredPropertyPermissions = propertyPermissions.filter((perm) => perm && perm != 'all' && perm != 'blank');
          const selectedCommunities = comResponse.filter((com) => filteredPropertyPermissions?.includes(com.sfid));
          dispatch(setCommunities(selectedCommunities));
          dispatch(setCommunitiesSelected(selectedCommunities));
        }
      }
    } else if (isComError) {
      dispatch(setCommunities([]));
      dispatch(setCommunitiesSelected([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMember?.property_permissions, isComFetching, isComLoading, isComSuccess, teamMember?.master]);

  useEffect(() => {
    if (params?.campaign_sfid && entitiesCampaigns.length > 0) {
      const currentCampaign = entitiesCampaigns.find((camp) => camp.id === params?.campaign_sfid);
      if (currentCampaign) {
        dispatch(setDashboard({type: 'campaigns', data: {...currentCampaign, sfid: currentCampaign.sfid, name: currentCampaign.name}}));
      }
    } else if (!('campaign_sfid' in params)) {
      let campAmount = entitiesCampaigns.length || 0;
      if (params?.community_sfid) {
        const fileteredCamps = entitiesCampaigns.filter((camp) => camp.property_sfid == params.community_sfid);
        campAmount = fileteredCamps.length || 0;
      }
      dispatch(setDashboard({type: 'campaigns', data: {sfid: 'all', name: `All campaigns (${campAmount})`}}));
    }
  }, [entitiesCampaigns, params]);

  useEffect(() => {
    if (params?.community_sfid && entitiesCommunities.length > 0) {
      const currentCommunity = entitiesCommunities.find((com) => com.sfid === params?.community_sfid);
      if (currentCommunity) {
        dispatch(setDashboard({type: 'communities', data: {sfid: currentCommunity.sfid, name: currentCommunity.name}}));
      }
    } else if (!('community_sfid' in params)) {
      const comAmount = entitiesCommunities.length || 0;
      dispatch(setDashboard({type: 'communities', data: {sfid: 'all', name: `All communities (${comAmount})`}}));
    }
  }, [params?.community_sfid, entitiesCommunities?.length]);

  useEffect(() => {
    if (!isCamFetching && !isCamLoading && isCamSuccess && camResponse?.result) {
      if (teamMember?.master) {
        const camps = camResponse?.result || [];
        dispatch(setCampaigns(camps));
      } else if (comResponse.length > 0) {
        const propertyPermissions = teamMember?.property_permissions || [];
        let filteredPropertyPermissions = [];
        if (isAdministrator) {
          filteredPropertyPermissions = comResponse.map((com) => com.sfid);
        } else if (propertyPermissions?.length > 0) {
          filteredPropertyPermissions = propertyPermissions.filter((perm) => perm && perm != 'all' && perm != 'blank');
        }
        const selectedCommunities = comResponse.filter((com) => filteredPropertyPermissions.includes(com.sfid));
        const communitiesSfids = selectedCommunities.map((com) => com.sfid);
        const selectedCampaigns = camResponse?.result.filter((camp) => communitiesSfids.includes(camp.property_sfid));
        dispatch(setCampaigns(selectedCampaigns));
      }
    }
  }, [
    isCamFetching,
    isCamLoading,
    isCamSuccess,
    teamMember?.master,
    teamMember?.property_permissions,
    isAdministrator,
    comResponse,
    camResponse
  ]);

  useEffect(() => {
    const allCampaigns = [...entitiesCampaigns];
    if (params?.community_sfid && allCampaigns.length > 0) {
      const filteredCampaigns = allCampaigns.filter((camp) => camp.property_sfid === params.community_sfid);
      dispatch(setCampaignsSelected(filteredCampaigns));
    } else if (allCampaigns.length > 0) {
      dispatch(setCampaignsSelected(allCampaigns));
    } else {
      dispatch(setCampaignsSelected([]));
    }
  }, [entitiesCampaigns?.length, params]);

  useEffect(() => {
    if (!isResFetching && !isResLoading && isResSuccess) {
      if (teamMember?.master) {
        dispatch(setResidents(resResponse));
      } else if (comResponse.length > 0) {
        let filteredPropertyPermissions = [];
        const propertyPermissions = teamMember?.property_permissions || [];

        if (isAdministrator) {
          filteredPropertyPermissions = comResponse.map((com) => com.sfid);
        } else if (propertyPermissions?.length > 0) {
          filteredPropertyPermissions = propertyPermissions.filter((perm) => perm && perm != 'all' && perm != 'blank');
        }
        const selectedCommunities = comResponse.filter((com) => filteredPropertyPermissions.includes(com.sfid));
        const communitiesSfids = selectedCommunities.map((com) => com.sfid);
        const selectedResidents = resResponse.filter((res) => communitiesSfids.includes(res.property__c));
        dispatch(setResidents(selectedResidents));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isResFetching,
    isResLoading,
    isResSuccess,
    isComFetching,
    isComLoading,
    isComSuccess,
    teamMember?.property_permissions,
    teamMember?.master
  ]);

  useEffect(() => {
    if (isAuthorized && acResponse.length > 0 && params?.account_sfid && teamMember?.id) {
      const company = acResponse.find((acc) => acc.sfid === params.account_sfid);
      const companyName = company ? company.name : '';

      window.intercomSettings = {
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        user_id: teamMember.id,
        company: {
          company_id: params.account_sfid,
          name: companyName
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, isAcFetching, isAcLoading, isAcSuccess, params?.account_sfid, teamMember?.id]);

  const mainComponent = useMemo(() => {
    if (dashboardAccount?.sfid) {
      return (
        <>
          <Header />
          <Box
            component="main"
            sx={{
              marginTop: `${headerHeight}px`,
              width: '100%',
              p: `${mainContentPaddingHeight}px ${mainContentPaddingWidth}px`
            }}
          >
            <Outlet />
            <AlertsDialog />
            <ActiveModal />
          </Box>
        </>
      );
    }
  }, [dashboardAccount]);

  return <Box sx={{display: 'flex', width: '100%'}}>{mainComponent}</Box>;
};

export default MainLayout;
