import axios from 'axios';
import {createApi} from '@reduxjs/toolkit/query/react';

import CONSTANTS from '../../commons/constants/envConstants';
import {getCurrentToken, initializeToken} from '../../commons/utils/cookies';

initializeToken();

const axiosBaseQuery =
  () =>
  async ({url, method, data, params, headers}) => {
    try {
      const baseUrl = CONSTANTS.BASE_API_URL;
      const token = await getCurrentToken();
      const configuredHeaders = {
        'X-Session-Token': token,
        'Content-Type': 'application/json',
        dealsAPIKey: CONSTANTS.BASE_API_KEY,
        ...headers
      };

      const result = await axios({
        url: `${baseUrl}${url}`,
        method,
        data,
        params,
        headers: configuredHeaders
      });

      return {data: result.data?.data ?? result.data};
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };

export const coreApiSlice = createApi({
  reducerPath: 'coreApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAccount: builder.query({
      query: (sfid) => ({
        url: 'accounts/',
        params: {sfid},
        method: 'get'
      }),
      providesTags: (_res, err, arg) => !err && [{type: 'Accounts', id: arg}]
    }),
    getChildAccounts: builder.query({
      query: (sfid) => ({
        url: `accounts/${sfid}/children/`,
        method: 'get'
      }),
      providesTags: [{type: 'ChildAccounts', id: 'LIST'}]
    }),
    getAccounts: builder.query({
      query: () => ({
        url: 'accounts/list/',
        method: 'get'
      }),
      providesTags: [{type: 'Accounts', id: 'LIST'}],
      transformResponse: (response) =>
        response.filter((account) => account.sfid && account.name).sort((a, b) => a.name.localeCompare(b.name))
    }),
    getAccountAlerts: builder.query({
      query: (sfid) => ({
        url: `accounts/${sfid}/alerts/`,
        method: 'get'
      })
    }),
    getAccountFeed: builder.query({
      query: (sfid) => ({
        url: `account/${sfid}/feed`,
        method: 'get'
      })
    }),
    sendActionFeedback: builder.mutation({
      query: ({sfid, data}) => ({
        url: `account/${sfid}/send_slack_action_update`,
        method: 'post',
        data
      })
    }),
    sendOfferConfirmation: builder.mutation({
      query: ({account, resident, data}) => ({
        url: `account/${account}/feed/${resident}/confirm`,
        method: 'post',
        data
      }),
      onQueryStarted: async ({account, resident, data}, {dispatch, queryFulfilled}) => {
        const patchResult = dispatch(
          coreApiSlice.util.updateQueryData('getAccountFeed', account, (draft) => {
            const feedToUpdate = draft.rows.find((d) => d.id === data?.renter_action_id && d.contact_sfid === resident);
            if (feedToUpdate) {
              feedToUpdate.is_confirmed = true;
              feedToUpdate.confirmed_by = data?.confirmed_by;
              feedToUpdate.confirmed_date = data?.confirmed_date;
            }
          })
        );
        try {
          await queryFulfilled;
        } catch (error) {
          patchResult.undo();
        }
      }
    }),
    getAccountInvoice: builder.query({
      query: (sfid) => ({
        url: `invoice/${sfid}`,
        method: 'get'
      })
    }),
    getPastInvoicesList: builder.query({
      query: (sfid) => ({
        url: `invoice/${sfid}/past/`,
        method: 'get'
      })
    })
  })
});

export const {
  useGetAccountAlertsQuery,
  useLazyGetAccountQuery,
  useLazyGetChildAccountsQuery,
  useLazyGetAccountsQuery,
  useLazyGetAccountAlertsQuery,
  useGetAccountFeedQuery,
  useSendActionFeedbackMutation,
  useSendOfferConfirmationMutation,
  useLazyGetAccountInvoiceQuery,
  useLazyGetPastInvoicesListQuery
} = coreApiSlice;
