import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {Box, Paper, Stack, Typography} from '@mui/material';

import {
  useGetConcessionSavingByCampaignSfidQuery,
  useGetConcessionSavingByPropertySfidQuery,
  useUpdateConcessionSavingByCampaignSfidMutation,
  useUpdateConcessionSavingByPropertySfidMutation
} from '../../../../../features/coreApi/metricsSlice';
import {getDashboardAccount, getDashboardCampaign, getDashboardCommunity} from '../../../../../app/store/reducers/dashboard';
import {getCampaigns, getCommunities} from '../../../../../app/store/reducers/entities';
import {getIsStakeAdmin} from '../../../../../app/store/reducers/session';
import {StyledMarginWidthDividerSm} from '../../../@extended/Divider';
import {ConcessionsHeader, ConcessionsInfo, Savings} from './ConcessionCompsInfo';
import {getOpenItem} from '../../../../../app/store/reducers/menu';

import {ComplexLoadingBox} from '../../../@extended/LoadingBox';
import ErrorBox from '../../../@extended/ErrorBox';
import VisibilitySwitch from '../../../@extended/VisibilitySwitch';
import {useParams} from 'react-router-dom';

const ConcessionComps = () => {
  const params = useParams();
  const openItem = useSelector(getOpenItem);
  const dashboardAccount = useSelector(getDashboardAccount);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const [visible, setVisible] = useState(false);
  const [editedValues, setEditedValues] = useState({});

  // campaign level
  const dashboardCampaign = useSelector(getDashboardCampaign);
  const campaigns = useSelector(getCampaigns);
  const campaign = params?.campaign_sfid && campaigns.find((c) => c.id === dashboardCampaign.sfid);

  const {data, isSuccess, isError, isFetching, isLoading} = useGetConcessionSavingByCampaignSfidQuery(params?.campaign_sfid, {
    skip: !params?.campaign_sfid || (!visible && !isStakeAdmin)
  });
  const [
    updateConcessionSavingByCampaignSfid,
    {data: updatedValues, isSuccess: isApplySuccess, isLoading: isApplyLoading, isFetching: isApplyFetching}
  ] = useUpdateConcessionSavingByCampaignSfidMutation();

  // portfolio level
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const communities = useSelector(getCommunities);
  const community = openItem == 'communities' && communities.find((c) => c.sfid === dashboardCommunity.sfid);

  const {
    data: propertyData,
    isSuccess: isPropertySuccess,
    isLoading: isPropertyLoading,
    isFetching: isPropertyFetching,
    isError: isPropertyError
  } = useGetConcessionSavingByPropertySfidQuery(params?.community_sfid, {
    skip: !params?.community_sfid || params?.campaign_sfid || (!visible && !isStakeAdmin) || campaign?.status === 'Draft'
  });

  const [
    updateConcessionSavingByPropertySfid,
    {data: updatedPropertyValues, isSuccess: isPropertyApplySuccess, isLoading: isPropertyApplyLoading, isFetching: isPropertyApplyFetching}
  ] = useUpdateConcessionSavingByPropertySfidMutation();

  useEffect(() => {
    if (campaign) {
      setVisible(!campaign?.hidden_loyalty_cloud_features__c?.includes('concessions_savings'));
    } else if (community) {
      setVisible(!community?.hidden_loyalty_cloud_features__c?.includes('concessions_savings'));
    }
  }, [campaign, isStakeAdmin, community]);

  const handleApply = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (dashboardCampaign.sfid && dashboardCampaign.sfid !== 'all') {
      updateConcessionSavingByCampaignSfid({sfid: dashboardCampaign.sfid, params: editedValues});
    } else if (dashboardCommunity.sfid && dashboardCommunity.sfid !== 'all') {
      updateConcessionSavingByPropertySfid({sfid: dashboardCommunity.sfid, params: editedValues});
    }
  };

  const concessionData = useMemo(() => {
    const defaultResponse = {
      is_visible: false,
      is_loading: false
    };

    if (!visible && !isStakeAdmin) {
      return defaultResponse;
    }

    if (isLoading || isFetching || isPropertyFetching || isPropertyLoading) {
      return {
        is_loading: true
      };
    }

    if (isApplySuccess) {
      if (updatedValues?.is_supported == false) {
        return {...data, is_visible: true, is_loading: false};
      } else {
        return {...updatedValues, is_visible: true, is_loading: false};
      }
    }

    if (isPropertyApplySuccess) {
      if (updatedPropertyValues?.is_supported == false) {
        return {...data, is_visible: true, is_loading: false};
      } else {
        return {...updatedPropertyValues, is_visible: true, is_loading: false};
      }
    }

    if (isSuccess && params?.campaign_sfid) {
      if (data?.is_supported == false) {
        return defaultResponse;
      } else {
        return {...data, is_visible: true, is_loading: false};
      }
    }

    if (isPropertySuccess && params?.community_sfid) {
      if (propertyData?.is_supported == false) {
        return defaultResponse;
      } else {
        return {...propertyData, is_visible: true, is_loading: false};
      }
    }

    return defaultResponse;
  }, [
    params,
    visible,
    isStakeAdmin,
    isLoading,
    isFetching,
    isPropertyFetching,
    isPropertyLoading,
    isApplySuccess,
    updatedValues,
    isPropertyApplySuccess,
    updatedPropertyValues,
    isSuccess,
    isPropertySuccess
  ]);

  const mainComponent = useMemo(() => {
    if (campaign?.status === 'Draft') {
      return (
        <Paper sx={{p: 2, width: '100%', height: '100%', boxShadow: 'none', borderRadius: 2}}>
          <Box width="100%" height={'100px'} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
              Concession comps data is not available for draft campaigns.
            </Typography>
          </Box>
        </Paper>
      );
    } else if (concessionData?.is_visible == false) {
      return (
        <Paper sx={{p: 2, width: '100%', height: '100%', boxShadow: 'none', borderRadius: 2}}>
          <Box width="100%" height={'100px'} display="flex" justifyContent="center" alignItems="center">
            <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
              No concession comps data available.
            </Typography>
          </Box>
        </Paper>
      );
    } else if (concessionData?.is_loading) {
      return (
        <Paper sx={{p: 2, width: '100%', height: '100%', boxShadow: 'none', borderRadius: 2}}>
          <ComplexLoadingBox isLoading={concessionData.is_loading} />
        </Paper>
      );
    } else if (concessionData?.is_visible && (visible || isStakeAdmin)) {
      let savings;

      if (isApplyFetching || isApplyLoading || isPropertyApplyFetching || isPropertyApplyLoading) {
        savings = <ComplexLoadingBox isLoading={isApplyFetching || isApplyLoading || isPropertyApplyFetching || isPropertyApplyLoading} />;
      } else if (isSuccess || isApplySuccess || isPropertySuccess || isPropertyApplySuccess) {
        savings = (
          <>
            <Savings data={concessionData.current_market_data} mainInfo={concessionData.projected_savings} />
            <Savings data={concessionData.historic_market_data} mainInfo={concessionData.historic_savings} />
          </>
        );
      }

      return (
        <Paper sx={{p: 2, width: '100%', height: '100%', boxShadow: 'none', borderRadius: 2}}>
          <Stack direction="column" rowGap={2} divider={<StyledMarginWidthDividerSm />}>
            <ConcessionsHeader data={concessionData.concessions} />
            <ConcessionsInfo
              data={concessionData.comp_filters}
              editedValues={editedValues}
              setEditedValues={setEditedValues}
              handleApply={handleApply}
            />
            {savings}
            {isStakeAdmin && (
              <VisibilitySwitch
                id={dashboardCampaign?.sfid}
                accountName={dashboardAccount?.name}
                setVisible={setVisible}
                isVisible={visible}
                modelType="campaign"
                featureType="concessions_savings"
              />
            )}
          </Stack>
        </Paper>
      );
    } else if (isError || isPropertyError) {
      return (
        <Paper sx={{p: 2, width: '100%', height: '100%', boxShadow: 'none', borderRadius: 2}}>
          <ErrorBox />
        </Paper>
      );
    }
  }, [
    concessionData,
    handleApply,
    isPropertySuccess,
    isSuccess,
    visible,
    isStakeAdmin,
    dashboardCampaign,
    dashboardAccount,
    isStakeAdmin,
    isError,
    editedValues,
    isApplyFetching,
    isApplyLoading,
    isApplySuccess,
    JSON.stringify(updatedValues),
    isPropertyError,
    isPropertyApplyFetching,
    isPropertyApplyLoading,
    isPropertyApplySuccess,
    JSON.stringify(updatedPropertyValues)
  ]);

  return mainComponent;
};

export default ConcessionComps;
