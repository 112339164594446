import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {Divider, Stack, Typography} from '@mui/material';

import {ExposureCompColumns, valueGetter} from '../../../../../constants/tableColumns';
import {StyledTable} from '../../../../@extended/CustomTables';
import TableBodyCell from '../../../../@extended/TableBodyCell';
import {CustomNoRowsOverlay, ExportToolBarWithSearch} from '../../../../@extended/DataGridTables';
import {sortNumbers, sortStrings} from '../../../../../utils/sorting';
import {useSelector} from 'react-redux';
import {getDashboardAccount} from '../../../../../../app/store/reducers/dashboard';

const ExposureGlance = ({data}) => {
    const dashboardAccount = useSelector(getDashboardAccount);
    const fileName = dashboardAccount?.name ? `${dashboardAccount.name.replace(/ /g, '_')}_vacancy_glance_report` : 'vacancy_glance_report';

    const columns = useMemo(
        () => [
            ...ExposureCompColumns.map((column) => ({
                ...column,
                field: column.id,
                headerName: column.label,
                renderCell: (params) => <TableBodyCell column={column} params={params} />,
                renderEditCell: (params) => <TableBodyCell column={column} params={params} />,
                valueFormatter: (params) => valueGetter(params, column),
                sortComparator: column.numeric ? (v1, v2) => sortNumbers(v1, v2) : (v1, v2) => sortStrings(v1, v2)
            }))
        ],
        []
    );

    return (
        <Stack direction={'column'} divider={<Divider />}>
            <Stack sx={{p: 2}}>
                <Typography variant={'subtitle2'} fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                    All residents with leases expiring in the next 120 days. Compare your in place rents to current market prices for
                    comparable units.
                </Typography>
            </Stack>
            <div style={{minHeight: 100, height: '100%', width: '100%'}}>
                <StyledTable
                    columns={columns}
                    rows={[...data]}
                    pagination
                    pageSizeOptions={[10, 20, 50, 100]}
                    slots={{
                        toolbar: () => <ExportToolBarWithSearch defaultFilename={fileName} />,
                        noRowsOverlay: () => <CustomNoRowsOverlay msg={'There is no vacant units'} />
                    }}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}}
                    }}
                    autoHeight
                    density="standard"
                    rowHeight={40}
                    disableRowSelectionOnClick
                    getRowId={(row) => row.id}
                    sx={{
                        '& .MuiIconButton-root': {
                            fontSize: '1.25rem'
                        }
                    }}
                />
            </div>
        </Stack>
    );
};

ExposureGlance.propTypes = {
    data: PropTypes.array
};

export default ExposureGlance;
