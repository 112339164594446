import React from 'react';
import PropTypes from 'prop-types';

import {Box, Typography} from '@mui/material';

const EmptyBox = ({text = null, height = '70vh'}) => {
  return (
    <Box width="100%" height={height} display="flex" justifyContent="center" alignItems="center" p={'0 24px'}>
      <Typography variant="subtitle1" fontWeight={'fontWeightRegular'} color={'text.subtitle'} textAlign={'center'}>
        {text || 'No data yet.'}
      </Typography>
    </Box>
  );
};

EmptyBox.propTypes = {
  text: PropTypes.string,
  height: PropTypes.string
};

export default EmptyBox;
