import dayjs from 'dayjs';

export const monthsByName = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
};

export const monthsByIdxFull = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};

export const findLastTwelveMonthsWithYYYY = () => {
    const monthsAndYearsByDefault = [];

    let i = 12;
    while (i >= 1) {
        monthsAndYearsByDefault.push(dayjs().subtract(i, 'months').format('MMM YYYY'));
        i--;
    }

    return monthsAndYearsByDefault;
};

export const findLastTwelveMonthsWithYY = () => {
    const monthsAndYearsByDefault = [];

    let i = 12;
    while (i >= 1) {
        monthsAndYearsByDefault.push(dayjs().subtract(i, 'months').format("MMM'YY"));
        i--;
    }

    return monthsAndYearsByDefault;
};

export const formatDateString = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return null;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
};

export const extractZipcode = (address) => {
    if (!address) return null;

    // Try multiple patterns to find the zipcode

    // 1. Look for pattern: ", STATE 12345" or ", STATE 12345-6789"
    // This is the most common format in US addresses
    const afterStatePattern = /,\s*[A-Z]{2}\s+(\d{5}(-\d{4})?)/;
    const stateMatch = address.match(afterStatePattern);
    if (stateMatch) return stateMatch[1];

    // 2. Look for pattern: "STATE 12345" (without comma)
    const noCommaStatePattern = /\s[A-Z]{2}\s+(\d{5}(-\d{4})?)/;
    const noCommaMatch = address.match(noCommaStatePattern);
    if (noCommaMatch) return noCommaMatch[1];

    // 3. Just look for 5 digits that might be a zipcode
    const digitPattern = /\b(\d{5}(-\d{4})?)\b/g;
    const allDigits = [...address.matchAll(digitPattern)].map((m) => m[1]);

    // If we have exactly one 5-digit number, it's likely the zipcode
    if (allDigits.length === 1) return allDigits[0];

    // 4. If we have multiple 5-digit numbers, the last one is most likely the zipcode
    if (allDigits.length > 1) return allDigits[allDigits.length - 1];

    return null;
};

export const extractCityAndState = (address) => {
    if (!address) return {city: null, state: null};

    // Split the address by commas
    const parts = address.split(',').map((part) => part.trim());

    let city = null;
    let state = null;

    // The state with zip pattern is usually in the format "STATE ZIP"
    const stateZipPattern = /^([A-Z]{2})\s+\d{5}(-\d{4})?/;

    // Check parts from end to beginning (most addresses have city/state near the end)
    for (let i = parts.length - 1; i >= 0; i--) {
        const part = parts[i];

        // Check for "STATE ZIP" pattern
        const stateZipMatch = part.match(stateZipPattern);
        if (stateZipMatch) {
            state = stateZipMatch[1];
            // City is likely in the previous part
            if (i > 0) {
                city = parts[i - 1];
            }
            break;
        }

        // Check for standalone state
        const stateMatch = part.match(/^([A-Z]{2})$/);
        if (stateMatch) {
            state = stateMatch[1];
            // City is likely in the previous part
            if (i > 0) {
                city = parts[i - 1];
            }
            break;
        }

        // Check if state is embedded in a part with other info
        const embeddedStateMatch = part.match(/\b([A-Z]{2})\b/);
        if (embeddedStateMatch && !part.match(/USA|United States/i)) {
            state = embeddedStateMatch[1];

            // If this part has both state and zipcode, city might be in previous part
            if (i > 0 && part.match(/\d{5}/)) {
                city = parts[i - 1];
            }
        }
    }

    return {city, state};
};
