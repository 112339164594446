import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import {getCampaigns} from '../../../../../app/store/reducers/entities';

import CampaignsTable from '../Campaign/CampaignsTable';
import DetailsContent from './DetailsContent';
import FeedComponent from '../Feed/FeedComponent';
import Glance from './Glance/Glance';

const PortfolioTab = () => {
    const campaigns = useSelector(getCampaigns);

    return (
        <Stack direction={'column'} rowGap={3} sx={{width: '100%'}}>
            <DetailsContent />
            <FeedComponent />
            <Glance />
            <CampaignsTable data={[...campaigns]} />
        </Stack>
    );
};

export default PortfolioTab;
