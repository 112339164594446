import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {getAuthorizedStatus} from '../../app/store/reducers/session';
import {useLazyGetSessionInfoQuery, useLazyGetSsoSessionInfoQuery} from '../../features/loyaltyCloudApi/session';
import {getTokenVerificationLoadingStatus} from '../../app/store/reducers/loading';
import LoadingBox from '../../commons/components/@extended/LoadingBox';
import {getCurrentToken, initializeToken} from '../../commons/utils/cookies';

const TokenVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {search} = useLocation();
  const newParams = new URLSearchParams(search);
  const token = newParams.get('token');

  const isAuthorized = useSelector(getAuthorizedStatus);
  const tokenStatus = useSelector(getTokenVerificationLoadingStatus);

  const [verifyToken] = useLazyGetSessionInfoQuery();
  const [verifySsoToken] = useLazyGetSsoSessionInfoQuery();
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    const verifyTokenAndInitialize = async () => {
      const isRegularLogin = location.pathname === '/verify_login';
      const isSsoLogin = location.pathname === '/verify_sso_login';

      if (token && !tokenStatus.isLoading && !tokenStatus.isError && !tokenStatus.isSuccess) {
        try {
          if (window.heap) {
            window.heap.track('Auth Verification Started', {
              authType: isRegularLogin ? 'regular' : isSsoLogin ? 'sso' : 'unknown',
              path: location.pathname
            });
          }

          if (isRegularLogin) {
            await verifyToken(token).unwrap();
          } else if (isSsoLogin) {
            await verifySsoToken(token).unwrap();
          }
          await initializeToken();

          if (window.heap) {
            window.heap.track('Auth Verification Success', {
              authType: isRegularLogin ? 'regular' : isSsoLogin ? 'sso' : 'unknown'
            });
          }

          navigate('/');
        } catch (error) {
          console.error('Token verification failed:', error);
          if (window.heap) {
            window.heap.track('Auth Verification Failed', {
              authType: isRegularLogin ? 'regular' : isSsoLogin ? 'sso' : 'unknown',
              errorMessage: error.message || 'Unknown error',
              errorCode: error.code || 'unknown'
            });
          }

          navigate('/login');
        } finally {
          setIsVerifying(false);
        }
      }
    };

    verifyTokenAndInitialize();
  }, [token, verifyToken, verifySsoToken, location.pathname, tokenStatus, navigate, isVerifying]);

  useEffect(() => {
    const checkAuthorization = async () => {
      if (!isVerifying) {
        const currentToken = await getCurrentToken();
        if (isAuthorized && currentToken) {
          navigate('/');
        } else if (tokenStatus.isError || (!isAuthorized && !currentToken && !token)) {
          navigate('/login');
        }
      }
    };

    checkAuthorization();
  }, [isAuthorized, navigate, tokenStatus.isError, isVerifying, token]);

  return <LoadingBox />;
};

export default TokenVerification;
