import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {Button, CircularProgress, Dialog, DialogContent, Divider, Fade, Stack, Typography} from '@mui/material';
import {DialogTitleWithCloseIcon} from '../../@extended/CustomDialogs';

import {useAddCommunityMutation, useLazyGetCommunityByIdQuery} from '../../../../features/coreApi/communitiesSlice';
import {FormProvider, set, useForm} from 'react-hook-form';
import {getDashboardAccount} from '../../../../app/store/reducers/dashboard';
import {useSelector} from 'react-redux';
import {FormAmount, FormInput, FormNumber, GoogleAddressInputField, SimpleFormSelectWithLabel} from '../../@extended/Forms';
import {LoadingButton} from '@mui/lab';

const communitySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    units: Yup.number()
        .transform((value, originalValue) => {
            // Convert empty string to null to avoid NaN error
            return originalValue === '' ? undefined : value;
        })
        .typeError('Units must be a number')
        .required('Units amount is required'),
    rent: Yup.number()
        .transform((value, originalValue) => {
            return originalValue === '' ? undefined : value;
        })
        .typeError('Rent must be a number')
        .required('Rent is required')
        .min(1, "Rent can't be less than $1")
        .max(20000, "Rent can't be more than $20,000"),
    fullAddress: Yup.string().required('Full Address is required').min(5, 'Full Address must be 5 characters'),
    zipcode: Yup.string().required('Zipcode is required').min(5, 'Zipcode must be 5 characters'),
    rentLateDate: Yup.number()
        .transform((value, originalValue) => {
            return originalValue === '' ? undefined : value;
        })
        .typeError('Rent Late Date must be a number')
        .required('Rent Late Date is required'),
    link: Yup.string().required('Listing link is required'),
    state: Yup.string().required(),
    city: Yup.string().required(),
    status: Yup.string().required()
});

const propertyStatus = [
    {id: 'Test Prop', name: 'Test Prop'},
    {id: 'Proposal', name: 'Proposal'},
    {id: 'Onboarding', name: 'Onboarding'}
];

const NewCommunity = ({isOpen, setOpen}) => {
    const dashboadrAccount = useSelector(getDashboardAccount);
    const [isLoading, setIsLoading] = useState(false);

    const [addCommunity] = useAddCommunityMutation();

    const methods = useForm({
        defaultValues: {
            name: '',
            units: 0,
            rent: '',
            fullAddress: '',
            zipcode: '',
            rentLateDate: 0,
            link: '',
            state: '',
            city: '',
            status: ''
        },
        mode: 'all',
        resolver: yupResolver(communitySchema)
    });

    const {
        reset,
        getValues,
        formState: {isValid, isDirty}
    } = methods;

    const handleClose = () => {
        reset();
        setIsLoading(false);
        setOpen(false);
    };

    const onSubmit = async () => {
        try {
            setIsLoading(true);

            const data = getValues();
            let formData = new FormData();

            const communityData = {
                full_address: data['fullAddress'],
                account_sfid: dashboadrAccount.sfid,
                name: data['name'],
                units: data['units'],
                listings_link: data['link'],
                rent: data['rent'],
                rent_late_date: data['rentLateDate'],
                zipcode: data['zipcode'],
                statecode: data['state'],
                city: data['city'],
                status: data['status']
            };

            Object.entries(communityData).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    formData.append(key, value);
                }
            });

            await addCommunity(formData);
            handleClose();
        } catch (error) {
            console.error('Error adding community:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={isOpen} onClose={() => handleClose()} fullWidth maxWidth={'lg'}>
            <DialogTitleWithCloseIcon onClose={() => handleClose()}>New Community</DialogTitleWithCloseIcon>
            <DialogContent dividers sx={{pt: 0, height: '50vh'}}>
                <FormProvider {...methods}>
                    <form style={{width: '100%', height: '100%'}}>
                        <Stack
                            direction="column"
                            rowGap={2}
                            width={'100%'}
                            height={'100%'}
                            sx={{p: '24px 0'}}
                            justifyContent={'space-between'}
                        >
                            <Stack direction="column" rowGap={3}>
                                <Stack direction="column" rowGap={1}>
                                    <Stack direction="row" columnGap={1}>
                                        <FormInput name="name" label="Rental Community Name" />
                                        <GoogleAddressInputField name="fullAddress" label="Address" required={true} />
                                    </Stack>
                                    <Stack direction="row" columnGap={1}>
                                        <FormNumber name="units" label="Total Units" type="number" />
                                        <FormAmount name="rent" label="Average Rent Today" />
                                    </Stack>
                                    <Stack direction="row" columnGap={1}>
                                        <FormInput name="link" label="Link to your online listings" />
                                        <FormNumber name="rentLateDate" label="Rent Late Date" />
                                    </Stack>
                                    <Stack direction="row" columnGap={1} width={'calc(50% - 4px)'}>
                                        <SimpleFormSelectWithLabel
                                            name="status"
                                            options={[{id: 'default', name: 'Select a property status'}, ...propertyStatus]}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack direction={'column'} rowGap={1} justifyContent={'space-between'} alignItems={'flex-end'}>
                                <Fade in={isLoading}>
                                    <Typography variant="body2" color={'text.subtitle'}>
                                        Adding a new community, it will take a few seconds...
                                    </Typography>
                                </Fade>
                                <LoadingButton
                                    variant="contained"
                                    loading={isLoading}
                                    color="secondary"
                                    onClick={onSubmit}
                                    disabled={!(isValid && isDirty)}
                                >
                                    Add Community
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </form>
                </FormProvider>
            </DialogContent>
        </Dialog>
    );
};

export default NewCommunity;
