import {createSlice} from '@reduxjs/toolkit';
import {sessionApi} from '../../../features/loyaltyCloudApi/session';
import {reviewsApi} from '../../../features/loyaltyCloudApi/reviewsSlice';
import {loyaltyCloudApi} from '../../../features/loyaltyCloudApi/apiSlice';
import {offersSlice} from '../../../features/actionsApi/offersSlice';
import {coreMetricsApi} from '../../../features/coreApi/metricsSlice';
import {residentsApi} from '../../../features/coreApi/residentsSlice';
import {contactListApi} from '../../../features/loyaltyCloudApi/contactListSlice';
import {coreApiSlice} from '../../../features/coreApi/apiSlice';
import {communitiesApi} from '../../../features/coreApi/communitiesSlice';

const initialState = {
    success: null,
    error: null
};

const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        resetAlerts: () => {
            return initialState;
        },
        setSuccess: (state, action) => {
            state.success = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(sessionApi.endpoints.updateLoginSession.matchRejected, (state, action) => {
                state.error = action.error.message;
            })
            .addMatcher(reviewsApi.endpoints.editReview.matchFulfilled, (state) => {
                state.success = 'Review updated successfully';
            })
            .addMatcher(reviewsApi.endpoints.editReview.matchRejected, (state, action) => {
                state.error = action.error.message;
            })
            .addMatcher(reviewsApi.endpoints.createReview.matchFulfilled, (state) => {
                state.success = 'Review created successfully';
            })
            .addMatcher(reviewsApi.endpoints.createReview.matchRejected, (state, action) => {
                state.error = action.error.message;
            })
            .addMatcher(loyaltyCloudApi.endpoints.updateTeamMemberInfo.matchFulfilled, (state) => {
                state.success = 'Info updated successfully';
            })
            .addMatcher(loyaltyCloudApi.endpoints.updateTeamMemberInfo.matchRejected, (state, action) => {
                state.error = action.error.message;
            })
            .addMatcher(offersSlice.endpoints.editOffer.matchFulfilled, (state) => {
                state.success = 'Offer updated successfully';
            })
            .addMatcher(offersSlice.endpoints.editOffer.matchRejected, (state, action) => {
                state.error = action.payload?.data?.message || action.error?.message;
            })
            .addMatcher(offersSlice.endpoints.createNewOffer.matchFulfilled, (state) => {
                state.success = 'Offer created successfully';
            })
            .addMatcher(offersSlice.endpoints.createNewOffer.matchRejected, (state, action) => {
                state.error = action.payload?.data?.message || action.error?.message;
            })
            .addMatcher(offersSlice.endpoints.deleteOffer.matchFulfilled, (state) => {
                state.success = 'Offer deleted successfully';
            })
            .addMatcher(offersSlice.endpoints.deleteOffer.matchRejected, (state, action) => {
                state.error = action.payload?.data?.message || action.error?.message;
            })
            .addMatcher(coreMetricsApi.endpoints.updateFeatureVisibility.matchFulfilled, (state) => {
                state.success = 'Changes are updated successfully';
            })
            .addMatcher(coreMetricsApi.endpoints.updateFeatureVisibility.matchRejected, (state, action) => {
                state.error = action.payload?.data?.message || action.error?.message;
            })
            .addMatcher(residentsApi.endpoints.updateResidentInfo.matchFulfilled, (state) => {
                state.success = 'Changes are updated successfully';
            })
            .addMatcher(residentsApi.endpoints.updateResidentInfo.matchRejected, (state, action) => {
                state.error = action.payload?.data?.message || action.error?.message;
            })
            .addMatcher(residentsApi.endpoints.updateResidentsPayments.matchFulfilled, (state) => {
                state.success = 'Changes are updated successfully';
            })
            .addMatcher(residentsApi.endpoints.updateResidentsPayments.matchRejected, (state, action) => {
                state.error = action.payload?.data?.message || action.error?.message;
            })
            .addMatcher(loyaltyCloudApi.endpoints.addTeamMember.matchFulfilled, (state) => {
                state.success = 'Team member was added';
            })
            .addMatcher(loyaltyCloudApi.endpoints.addTeamMember.matchRejected, (state, action) => {
                const error = action.payload?.data?.message || action.payload?.data?.error || action.error?.message;
                state.error = error.replace(' for {}', '');
            })
            .addMatcher(residentsApi.endpoints.createResidentTransactions.matchFulfilled, (state) => {
                state.success = 'Money was sent successfully';
            })
            .addMatcher(residentsApi.endpoints.createResidentTransactions.matchRejected, (state, action) => {
                state.error = action.payload?.data?.message || action.error?.message;
            })
            .addMatcher(contactListApi.endpoints.updateContactListContacts.matchFulfilled, (state) => {
                state.success = 'Contacts in the offer updated successfully';
            })
            .addMatcher(contactListApi.endpoints.updateContactListContacts.matchRejected, (state, action) => {
                state.error = action.payload?.data?.message || action.error?.message;
            })
            .addMatcher(coreApiSlice.endpoints.sendActionFeedback.matchFulfilled, (state, action) => {
                state.success = action?.payload || 'We notified the team. Thank you!';
            })
            .addMatcher(coreApiSlice.endpoints.sendActionFeedback.matchRejected, (state, action) => {
                state.error = action.payload?.data?.error || 'Something went wrong, please try again later.';
            })
            .addMatcher(coreApiSlice.endpoints.sendOfferConfirmation.matchFulfilled, (state) => {
                state.success = 'Renter action is confirmed';
            })
            .addMatcher(coreApiSlice.endpoints.sendOfferConfirmation.matchRejected, (state, action) => {
                state.error = action.payload?.data?.error || action.error?.message;
            })
            .addMatcher(communitiesApi.endpoints.addCommunity.matchFulfilled, (state) => {
                state.success = 'A new community was created successfully';
            })
            .addMatcher(communitiesApi.endpoints.addCommunity.matchRejected, (state, action) => {
                state.error = 'Something went wrong, please try again later or contact support team.';
            });
    }
});

export const {resetAlerts, setSuccess, setError} = alertsSlice.actions;
export default alertsSlice.reducer;

export const getAlertsSuccess = (state) => state.alerts.success;
export const getAlertsError = (state) => state.alerts.error;
