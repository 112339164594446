import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {Button, Divider, Paper, Stack, Typography} from '@mui/material';

import {valueGetter} from '../../../../constants/tableColumns';
import {activeModal} from '../../../../../app/store/reducers/menu';
import {StyledTable} from '../../../@extended/CustomTables';
import {useGetAccountFeedQuery} from '../../../../../features/coreApi/apiSlice';
import {getCampaigns, getCommunities} from '../../../../../app/store/reducers/entities';
import {getIsStakeAdmin, getPropertyPermissions, getTeamMemberRole} from '../../../../../app/store/reducers/session';
import {ExportToolBarWithSearch} from '../../../@extended/DataGridTables';
import TableBodyCell from '../../../@extended/TableBodyCell';
import ErrorBox from '../../../@extended/ErrorBox';
import LoadingBox from '../../../@extended/LoadingBox';
import EmptyBox from '../../../@extended/EmptyBox';
import EditRenterAction from './EditRenterAction';
import ConfirmActionButton from './ConfirmActionButton';
import {maxWidth, minWidth} from '@mui/system';
import {sortNumbers, sortStrings} from '../../../../utils/sorting';

const FeedComponent = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const accountSfid = params?.account_sfid;
  const communitySfid = params?.community_sfid;
  const campaignSfid = params?.campaign_sfid;

  const campaigns = useSelector(getCampaigns);
  const communities = useSelector(getCommunities);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const teamMemberRole = useSelector(getTeamMemberRole);
  const isAdministrator = teamMemberRole === 'Administrator';
  const propertyPermissions = useSelector(getPropertyPermissions);

  const {data, isError, isLoading, isFetching} = useGetAccountFeedQuery(accountSfid, {skip: !accountSfid});

  const handleCellClick = (params) => {
    const {field, row} = params;
    if (field == 'renter_action' && row?.contact_sfid) {
      dispatch(activeModal({type: 'resident_info', sfid: row.contact_sfid}));
    }
  };

  const rowsToDisplay = useMemo(() => {
    if (data?.rows) {
      let rowsData = [];

      if (campaignSfid) {
        const campaign = campaigns.find((c) => c.sfid === campaignSfid);
        rowsData = data.rows.filter((row) => row.campaign === campaign?.name);
      } else if (communitySfid) {
        const community = communities.find((c) => c.sfid === communitySfid);
        rowsData = data.rows.filter((row) => row.property === community?.name);
      } else {
        if (!isStakeAdmin && !isAdministrator) {
          const permissionCommunities = communities.filter((community) => propertyPermissions.includes(community.sfid));
          const permissionsNames = permissionCommunities.map((community) => community.name);
          rowsData = data.rows.filter((row) => permissionsNames.includes(row.property));
        } else {
          rowsData = data.rows;
        }
      }

      return rowsData;
    } else {
      return [];
    }
  }, [data, campaignSfid, communitySfid, communities, campaigns, isStakeAdmin, isAdministrator, propertyPermissions]);

  const handleOpen = (row) => {
    dispatch(activeModal({type: 'edit_feed', data: row}));
  };

  const mainComponent = useMemo(() => {
    if (isLoading || isFetching) {
      return <LoadingBox />;
    } else if (isError && !isFetching && !isLoading) {
      return <ErrorBox customText={'Error fetching recent resident updates.'} />;
    } else {
      if (rowsToDisplay?.length === 0) {
        return (
          <EmptyBox
            text={
              'No recent resident updates. Ask your Stake customer success manager about additional physical marketing materials or digital messaging campaigns to boost engagement!'
            }
            height="20vh"
          />
        );
      } else {
        const columns = data?.columns.map((col) => {
          return {
            ...col,
            field: col.id,
            headerName: col.label,
            renderCell: (params) =>
              col.id == 'renter_action' ? (
                <Typography dangerouslySetInnerHTML={{__html: params.value}} />
              ) : (
                <TableBodyCell column={col} params={params} />
              ),
            valueFormatter: (params) => valueGetter(params, col),
            sortComparator: col.numeric ? (v1, v2) => sortNumbers(v1, v2) : (v1, v2) => sortStrings(v1, v2)
          };
        });

        columns.push({
          field: 'action',
          headerName: '',
          width: 100,
          renderCell: (params) => (
            <Stack direction={'row'} alignItems={'center'} height={'100%'}>
              <Button
                variant="contained"
                color="secondary"
                sx={{height: 'fit-content'}}
                onClick={() => handleOpen(params.row)}
                p={'0 12px'}
              >
                Flag
              </Button>
            </Stack>
          )
        });

        columns.push({
          field: 'confirm_action',
          headerName: '',
          width: 180,
          renderCell: (params) => <ConfirmActionButton row={params.row} />
        });

        return (
          <div style={{width: '100%'}}>
            <StyledTable
              rows={rowsToDisplay || []}
              columns={columns}
              slots={{toolbar: ExportToolBarWithSearch}}
              onCellClick={handleCellClick}
              pagination
              pageSizeOptions={[10, 25, 50, 100]}
              initialState={{pagination: {paginationModel: {pageSize: 10}}}}
              autoHeight
              disableRowSelectionOnClick
              getRowId={(row) => row.id}
              getRowHeight={() => 'auto'}
              sx={{
                '& .MuiDataGrid-cell': {
                  p: '8px 16px',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  maxWidth: '100%',
                  lineHeight: '1.5',
                  display: 'block'
                },
                '& .clickable-cell': {
                  cursor: 'pointer'
                }
              }}
            />
          </div>
        );
      }
    }
  }, [isError, isLoading, isFetching, rowsToDisplay, data, handleCellClick, handleOpen]);

  return (
    <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
      <Stack direction={'column'} divider={<Divider />}>
        <Stack direction={'column'} sx={{p: 2}}>
          <Typography variant="h5" fontWeight="fontWeightMedium">
            Resident Action Feed
          </Typography>
          <Typography variant="body2" color={'text.subtitle'}>
            See recent actions your Stakers have taken
          </Typography>
        </Stack>
        {mainComponent}
      </Stack>
      <EditRenterAction />
    </Paper>
  );
};

export default FeedComponent;
