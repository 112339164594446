import React, {Fragment, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import {Fullscreen, FullscreenExit} from '@mui/icons-material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  useLazyGetEmailHistoryQuery,
  useLazyGetNotificationHistoryQuery,
  useLazyGetTextHistoryQuery
} from '../../features/messagingApi/apiSlice';
import {StyledPaperScrollY} from '../../commons/components/@extended/StyledPaperScrollY';
import {findEmailSummaryStats, findPushSummaryStats, findTextSummaryStats} from '../../commons/utils/messagingUtils';
import {StyledMarginWidthDividerSm} from '../../commons/components/@extended/Divider';
import {monthsByIdxFull} from '../../commons/constants/dateConstants';
import {fontAwesomeIcons} from '../../commons/utils/icons';
import {getIsStakeAdmin} from '../../app/store/reducers/session';
import {useCreateResidentTransactionsMutation, useGetResidentTransactionsQuery} from '../../features/coreApi/residentsSlice';
import {moneyFormatterDecimal} from '../../commons/components/@extended/FormatDisplay';
import {FormAmount, SimpleFormSelectWithLabel} from '../../commons/components/@extended/Forms';
import {DialogTitleWithCloseIcon} from '../../commons/components/@extended/CustomDialogs';
import {getRandomValue, ResidentOffersColumns, valueGetter} from '../../commons/constants/tableColumns';
import TableBodyCell from '../../commons/components/@extended/TableBodyCell';
import {offersComparator} from '../../commons/components/HomeTabs/CRM/utils';
import {StyledTable} from '../../commons/components/@extended/CustomTables';
import {convertToLocalTime} from '../../commons/utils/date_utils';

const columns = [
  {title: 'Email Open Rate', key: 'emailOpenRate', width: '20%'},
  {title: 'Email Click Rate', key: 'emailClickRate', width: '20%'},
  {title: 'Push Delivery Rate', key: 'pushDeliveryRate', width: '20%'},
  {title: 'Push Click Rate', key: 'pushClickRate', width: '20%'},
  {title: 'Text Delivery Rate', key: 'textDeliveryRate', width: '20%'}
];

export const CommunicationHistory = ({resident}) => {
  const [fetchEmailHistory, {data: emails = [], isSuccess: isEmailSuccess, isLoading: isEmailLoading, isFetching: isEmailFetching}] =
    useLazyGetEmailHistoryQuery();

  const [fetchTextHistory, {data: texts = [], isSuccess: isTextSuccess, isLoading: isTextLoading, isFetching: isTextFetching}] =
    useLazyGetTextHistoryQuery();

  const [
    fetchNotificationHistory,
    {data: notifications = [], isSuccess: isNotificationSuccess, isLoading: isNotificationLoading, isFetching: isNotificationFetching}
  ] = useLazyGetNotificationHistoryQuery();

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (resident && resident?.mobile_user_id__c) {
      fetchEmailHistory(resident.mobile_user_id__c);
      fetchTextHistory(resident.mobile_user_id__c);
      fetchNotificationHistory(resident.mobile_user_id__c);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resident]);

  const allStats = useMemo(() => {
    const obj = {
      emailOpenRate: 'no data',
      emailClickRate: 'no data',
      pushDeliveryRate: 'no data',
      pushClickRate: 'no data',
      textDeliveryRate: 'no data'
    };

    if (isEmailSuccess && !isEmailFetching && !isEmailLoading && emails.length > 0) {
      const emailStats = findEmailSummaryStats(emails);
      obj.emailOpenRate = emailStats.openRate;
      obj.emailClickRate = emailStats.clickRate;
    }
    if (isTextSuccess && !isTextFetching && !isTextLoading && texts.length > 0) {
      const textStats = findTextSummaryStats(texts);
      obj.textDeliveryRate = textStats.deliveryRate;
    }
    if (isNotificationSuccess && !isNotificationFetching && !isNotificationLoading && notifications.length > 0) {
      const pushStats = findPushSummaryStats(notifications);
      obj.pushDeliveryRate = pushStats.deliveryRate;
      obj.pushClickRate = pushStats.clickRate;
    }

    return obj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEmailFetching,
    isEmailLoading,
    isEmailSuccess,
    isTextFetching,
    isTextLoading,
    isTextSuccess,
    isNotificationFetching,
    isNotificationLoading,
    isNotificationSuccess
  ]);

  const timelineData = useMemo(() => {
    const allData = [];

    if (emails.length > 0) {
      allData.push(...emails.map((ele) => ({...ele, activityType: 'email'})));
    }

    if (texts.length > 0) {
      allData.push(...texts.map((ele) => ({...ele, activityType: 'text'})));
    }

    if (notifications.length > 0) {
      allData.push(...notifications.map((ele) => ({...ele, activityType: 'push'})));
    }

    const sorted = allData.sort((a, b) => a.timestamp - b.timestamp);
    return sorted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEmailFetching,
    isEmailLoading,
    isEmailSuccess,
    isTextFetching,
    isTextLoading,
    isTextSuccess,
    isNotificationFetching,
    isNotificationLoading,
    isNotificationSuccess
  ]);

  const mainComponent = useMemo(() => {
    if (timelineData.length > 0) {
      let fullScreen;
      if (isOpen) {
        fullScreen = <OpenFullMessagingTimelines data={timelineData || []} isOpen={isOpen} setOpen={setOpen} />;
      } else {
        fullScreen = (
          <Box sx={{position: 'relative', width: '100%', height: 'fit-content'}}>
            <MessagingTimelinesIcon data={timelineData || []} isOpen={isOpen} setOpen={setOpen} />
            <StyledPaperScrollY
              sx={{width: '100%', height: '100%', maxHeight: '422px', padding: 1, position: 'relative', boxShadow: 'none'}}
            >
              <MessagingTimelines data={timelineData || []} />
            </StyledPaperScrollY>
          </Box>
        );
      }
      return (
        <Stack direction="column" rowGap={1}>
          <Typography variant="h6" fontWeight="fontWeightMedium">
            Communication History
          </Typography>
          <Typography variant="body1" color={'text.subtitle'}>
            Summary Stats
          </Typography>
          <Paper sx={{width: '100%', height: 'fit-content', boxShadow: 'none', p: 2, borderRadius: '8px'}}>
            <Stack direction={'row'} columnGap={2} sx={{width: '100%'}}>
              {columns.map((column) => (
                <Stack
                  key={column.title.toLowerCase().split(' ').join('-')}
                  direction={'column'}
                  rowGap={1}
                  sx={{width: column.width}}
                  divider={<StyledMarginWidthDividerSm />}
                >
                  <Typography variant="body1" fontWeight={'fontWeightMedium'}>
                    {column.title}
                  </Typography>
                  <Typography variant="h6" color={'text.subtitle'} sx={{p: '16px 0'}}>
                    {allStats[column.key] == 'no data' ? 'no data' : `${allStats[column.key]}%`}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Paper>
          <Typography variant="body1" color={'text.subtitle'}>
            Messaging Timeline
          </Typography>
          {fullScreen}
        </Stack>
      );
    } else {
      return (
        <Stack direction="column" rowGap={1}>
          <Typography variant="h6" fontWeight="fontWeightMedium">
            Communication History
          </Typography>
          <Paper sx={{width: '100%', height: 'fit-content', boxShadow: 'none', p: 2, borderRadius: '8px'}}>
            <Stack direction="row" justifyContent="center" alignItems="center" width={'100%'} height={'50px'}>
              <Typography variant="body1" color={'text.subtitle'}>
                Available soon
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      );
    }
  }, [timelineData, allStats, isOpen, setOpen]);
  return mainComponent;
};

CommunicationHistory.propTypes = {
  resident: PropTypes.object
};

const MessagingTimelines = ({data}) => {
  const theme = useTheme();

  let loadingComponent;
  let caughtUpIcon;

  if (data.length === 0) {
    loadingComponent = (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="200px">
        <Typography sx={{color: theme.palette.grey[600]}}>No history yet</Typography>
      </Box>
    );
  } else {
    caughtUpIcon = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: 1
        }}
      >
        <Stack direction="row" justifyContent="center" marginBottom={1}>
          <Box height="64px" sx={{borderRight: `1px solid ${theme.palette.grey[600]}`}} />
          <Box height="64px" />
        </Stack>
        <Avatar sx={{bgcolor: theme.palette.messaging.pushIcon, width: 40, height: 40}}>
          <FontAwesomeIcon icon={fontAwesomeIcons.faCheck} />
        </Avatar>
      </Box>
    );

    loadingComponent = (
      <Stack direction="column" rowGap={1} width="100%">
        {data.map((ele, idx) => (
          <HistoryDataElement key={`communication-history-data-${idx}`} ele={ele} isLastEle={idx === data.length - 1} idx={idx} />
        ))}
        {caughtUpIcon}
      </Stack>
    );
  }

  return loadingComponent;
};

MessagingTimelines.propTypes = {
  data: PropTypes.array
};

const HistoryDataElement = ({ele, isLastEle, idx}) => {
  const {activityType} = ele;

  const theme = useTheme();

  let iconComponent;
  let infoComponent;
  let title;
  let mainText;
  let statusFirst;
  let statusSecond;

  const positionCalc = idx % 2 === 0 ? 'calc(50% + 30px)' : 'calc(50% - 30px - 405px)';
  //"Thu, 07 Apr 2022 22:31:28 GMT" to "10:31 pm, April 7, 2022"
  const dateInfo = new Date(ele.js_datestamp);
  const date = dateInfo.getUTCDate();
  const month = monthsByIdxFull[dateInfo.getUTCMonth() + 1];
  const year = dateInfo.getUTCFullYear();
  const time = dateInfo.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
  const dateInfoString = `${time}, ${month} ${date}, ${year}`;

  const dividerComponent = (
    <Stack direction="row" justifyContent="center">
      <Box height="64px" sx={{borderRight: `1px solid ${theme.palette.grey[600]}`}} />
      <Box height="64px" />
    </Stack>
  );

  const emailIcon = (
    <Avatar sx={{bgcolor: theme.palette.messaging.emailIcon, width: 40, height: 40}}>
      <FontAwesomeIcon icon={fontAwesomeIcons.faInbox} />
    </Avatar>
  );

  const textIcon = (
    <Avatar sx={{bgcolor: theme.palette.messaging.textIcon, width: 40, height: 40}}>
      <FontAwesomeIcon icon={fontAwesomeIcons.faMessage} />
    </Avatar>
  );

  const pushIcon = (
    <Avatar sx={{bgcolor: theme.palette.messaging.pushIcon, width: 40, height: 40}}>
      <FontAwesomeIcon icon={fontAwesomeIcons.faMobileNotch} />
    </Avatar>
  );

  if (activityType === 'email') {
    iconComponent = emailIcon;
    mainText = 'Email sent';
    title = 'Email';
    statusFirst = ele.opened ? 'Opened' : 'Not Opened';
    statusSecond = ele.clicked ? 'Clicked' : 'Not Clicked';
  } else if (activityType === 'text') {
    iconComponent = textIcon;
    mainText = ele.body;
    title = 'Text';
    statusFirst = ele.delivered ? 'Delivered' : 'Not Delivered';
  } else if (activityType === 'push') {
    iconComponent = pushIcon;
    mainText = ele.message;
    title = 'Push Notification';
    statusFirst = ele.delivered ? 'Delivered' : 'Not Delivered';
    statusSecond = ele.clicked ? 'Clicked' : 'Not Clicked';
  }

  infoComponent = (
    <Box sx={{position: 'relative', display: 'flex', justifyContent: 'center', paddingTop: idx === 0 && '20px'}}>
      {iconComponent}
      <Box
        sx={{
          width: '405px',
          maxHeight: '260px',
          boxShadow: '0px 1px 4px 2px #0000000f',
          borderRadius: '10px',
          position: 'absolute',
          left: positionCalc,
          top: idx === 0 ? '2px' : '-16px',
          padding: 1
        }}
      >
        <Stack direction="column" rowGap={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">{title}</Typography>
            <Stack direction="column">
              <Typography variant="body2" color={'text.subtitle'} textAlign="right">
                {dateInfoString}
              </Typography>
              <Typography variant="body2" color={'text.subtitle'} textAlign="right">
                {statusFirst}
              </Typography>
              <Typography variant="body2" color={'text.subtitle'} textAlign="right">
                {statusSecond}
              </Typography>
            </Stack>
          </Stack>
          <Box sx={{textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '180px'}}>
            <Typography variant="body2">{mainText}</Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      {infoComponent}
      {!isLastEle && dividerComponent}
    </React.Fragment>
  );
};

HistoryDataElement.propTypes = {
  ele: PropTypes.object,
  isLastEle: PropTypes.bool,
  idx: PropTypes.number
};

const MessagingTimelinesIcon = ({isOpen, setOpen, data}) => {
  const theme = useTheme();

  let iconComponent;

  if (data.length > 0) {
    if (!isOpen) {
      iconComponent = (
        <Fullscreen
          sx={{
            fontSize: '40px',
            color: theme.palette.grey[300],
            transition: 'transform 0.15s ease-in-out',
            '&:hover': {transform: 'scale(1.3)'}
          }}
        />
      );
    } else {
      iconComponent = (
        <FullscreenExit
          sx={{
            fontSize: '40px',
            color: theme.palette.grey[300],
            transition: 'transform 0.15s ease-in-out',
            '&:hover': {transform: 'scale(1.3)'}
          }}
        />
      );
    }
  }

  return (
    <IconButton disableRipple onClick={() => setOpen(!isOpen)} sx={{position: 'absolute', zIndex: 2, p: 0, top: 20, left: 20}}>
      {iconComponent}
    </IconButton>
  );
};

MessagingTimelinesIcon.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.array
};

const OpenFullMessagingTimelines = ({isOpen, setOpen, data}) => {
  return (
    <Box sx={{position: 'relative', width: '100%', height: 'fit-content', border: '1px solid #e3e3e3', borderRadius: '16px'}}>
      <MessagingTimelinesIcon data={data} isOpen={isOpen} setOpen={setOpen} />
      <MessagingTimelines data={data} />
    </Box>
  );
};

OpenFullMessagingTimelines.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.array
};

export const NotStakeEscrowStatuses = new Set(['ApplicationApproved', 'DebitCardRequested', 'DebitCardActivated']);

export const ActivityFeed = ({resident}) => {
  const {stake_debit_status__c, mobile_user_id__c} = resident;
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const isResidentNotStakeEscrow = NotStakeEscrowStatuses.has(stake_debit_status__c);

  let visibleToStakeTeamComponent = <div></div>;

  const {
    data: fetchResultData,
    isLoading,
    isFetching,
    isError,
    isSuccess
  } = useGetResidentTransactionsQuery(mobile_user_id__c, {skip: !mobile_user_id__c});
  const [isSendMoneyDialogOpen, setSendMoneyDialogOpen] = useState(false);

  const residentTransactionActivities = useMemo(() => {
    if (isSuccess && !isError && !isLoading && !isFetching) {
      const transactions = fetchResultData?.activities ? [...fetchResultData.activities].reverse() : [];
      return transactions;
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching, isLoading, isError]);

  const residentUnclaimedTransactionActivities = useMemo(() => {
    if (isSuccess && !isError && !isLoading && !isFetching) {
      const transactions = fetchResultData?.unclaimedTransactions ? [...fetchResultData.unclaimedTransactions].reverse() : [];
      return transactions;
    } else {
      return [];
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching, isLoading, isError]);

  const residentTransactionBalances = useMemo(() => {
    if (isSuccess && !isError && !isLoading && !isFetching) {
      const currentBalance = moneyFormatterDecimal.format(fetchResultData?.currentBalance || 0);
      const lifetimeEarnings = moneyFormatterDecimal.format(fetchResultData?.lifetimeEarnings || 0);
      const unclaimedBalance = moneyFormatterDecimal.format(fetchResultData?.unclaimedBalance || 0);
      return [currentBalance, lifetimeEarnings, unclaimedBalance];
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isFetching, isLoading, isError]);

  if (isSuccess) {
    visibleToStakeTeamComponent = (
      <Fragment>
        <Stack direction="column" rowGap={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6" fontWeight="fontWeightMedium">
              Activity
            </Typography>
            {isResidentNotStakeEscrow && (
              <Button variant="contained" color="secondary" onClick={() => setSendMoneyDialogOpen(true)}>
                Send Money
              </Button>
            )}
          </Stack>
          <Stack direction="column" rowGap={1}>
            <Typography variant="body1" color={'text.subtitle'}>
              Balances
            </Typography>
            <BalancesTable data={residentTransactionBalances} />
          </Stack>
          {isStakeAdmin && (
            <Stack direction="column" rowGap={1}>
              <Typography variant="body1" color={'text.subtitle'}>
                Unclaimed Transactions
              </Typography>
              <TransactionsList data={residentUnclaimedTransactionActivities} type="unclaimed" />
            </Stack>
          )}
          {isStakeAdmin && (
            <Stack direction="column" rowGap={1}>
              <Typography variant="body1" color={'text.subtitle'}>
                Transactions
              </Typography>
              <TransactionsList data={residentTransactionActivities} type="claimed" />
            </Stack>
          )}
        </Stack>
        <SendMoneyDialog isOpen={isSendMoneyDialogOpen} setOpen={setSendMoneyDialogOpen} resident={resident} />
      </Fragment>
    );
  } else if (isError) {
    visibleToStakeTeamComponent = (
      <Paper sx={{width: '100%', padding: 2, boxShadow: 'none', height: '100px'}}>
        <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography variant="subtitle1" color={'text.subtitle'} fontWeight={'fontWeightRegular'} textAlign="center" lineHeight="1.25">
            Something went wrong. PLease come back later or add money to the account of Stake Escrow resident via Salesforce.
          </Typography>
        </Box>
      </Paper>
    );
  }

  return visibleToStakeTeamComponent;
};

ActivityFeed.propTypes = {
  resident: PropTypes.object
};

const balanceColumns = [
  {
    title: 'Current Balance',
    key: 'currentBalance',
    width: '33%',
    tooltip: 'The balance currently in this resident’s account. These funds do not need to be claimed.'
  },
  {
    title: 'Lifetime Earnings',
    key: 'lifetimeEarnings',
    width: '33%',
    tooltip: ''
  },
  {title: 'Unclaimed Balance', key: 'unclaimedBalance', width: '33%', tooltip: 'The balance that this resident currently needs to claim.'}
];

const BalancesTable = ({data}) => {
  return (
    <Paper sx={{width: '100%', height: 'fit-content', boxShadow: 'none', p: 2}}>
      <Stack direction={'row'} columnGap={2} sx={{width: '100%'}}>
        {balanceColumns.map((column, idx) => (
          <Stack
            key={column.title.toLowerCase().split(' ').join('-')}
            direction={'column'}
            rowGap={1}
            sx={{width: column.width}}
            divider={<StyledMarginWidthDividerSm />}
          >
            {column.tooltip ? (
              <Stack direction={'row'} columnGap={0.5} alignItems={'center'}>
                <Typography variant="body1" fontWeight={'fontWeightMedium'}>
                  {column.title}
                </Typography>
                <Tooltip title={column.tooltip} arrow>
                  <IconButton size="small" style={{marginLeft: '4px'}}>
                    <FontAwesomeIcon icon={fontAwesomeIcons.faCircleQuestionLight} color="#787878" />
                  </IconButton>
                </Tooltip>
              </Stack>
            ) : (
              <Typography variant="body1" fontWeight={'fontWeightMedium'}>
                {column.title}
              </Typography>
            )}
            <Typography variant="h6" color={'text.subtitle'} sx={{p: '16px 0'}}>
              {data[idx]}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Paper>
  );
};

BalancesTable.propTypes = {
  data: PropTypes.array
};

const TransactionsList = ({data, type}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (_e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const mainComponent = useMemo(() => {
    if (data && data.length > 0) {
      let transactionsComponent = [];
      if (type === 'claimed') {
        transactionsComponent = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ele, idx) => {
          const {amount, balance, direction, date, category, time} = ele;
          const formattedDate = convertToLocalTime({date, time});
          const sign = direction === 'Credit' ? '+' : '-';
          const displayAmount = `${sign}${moneyFormatterDecimal.format(amount)}`;
          const divider = idx === data.length - 1 && data.length <= 10 ? null : <Divider />;

          const comp = (
            <Fragment key={`transactions-list-${idx}`}>
              <Stack direction="row" justifyContent="space-between" sx={{padding: '24px 16px'}}>
                <Stack direction="column">
                  <Typography variant="body1" color={'text.subtitle'}>
                    {formattedDate}
                  </Typography>
                  <Typography variant="h6" fontWeight={'fontWeightRegular'}>
                    {category}
                  </Typography>
                </Stack>
                <Stack direction="column" alignItems="flex-end">
                  <Typography variant="h6" fontWeight={'fontWeightRegular'}>
                    {displayAmount}
                  </Typography>
                  <Typography variant="h6" fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
                    Balance: {moneyFormatterDecimal.format(balance)}{' '}
                  </Typography>
                </Stack>
              </Stack>
              {divider}
            </Fragment>
          );

          return comp;
        });
      } else {
        transactionsComponent = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((ele, idx) => {
          const {amount, date, category, time} = ele;
          const formattedDate = convertToLocalTime({date, time});
          const displayAmount = `${moneyFormatterDecimal.format(amount)}`;
          const divider = idx === data.length - 1 && data.length <= 10 ? null : <Divider />;

          const comp = (
            <Fragment key={`transactions-list-${idx}`}>
              <Stack direction="row" justifyContent="space-between" sx={{padding: '24px 16px'}}>
                <Stack direction="column">
                  <Typography variant="body1" color={'text.subtitle'}>
                    {formattedDate}
                  </Typography>
                  <Typography variant="h6" fontWeight={'fontWeightRegular'}>
                    {category}
                  </Typography>
                </Stack>
                <Stack direction="column" alignItems="flex-end">
                  <Typography variant="h6" fontWeight={'fontWeightRegular'}>
                    {displayAmount}
                  </Typography>
                </Stack>
              </Stack>
              {divider}
            </Fragment>
          );

          return comp;
        });
      }

      return (
        <Paper sx={{width: '100%', boxShadow: 'none'}}>
          {transactionsComponent}
          {data.length > 10 && (
            <TablePagination
              rowsPerPageOptions={[10, 50, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                '.MuiTablePagination-toolbar': {
                  padding: '0 16px'
                }
              }}
            />
          )}
        </Paper>
      );
    } else {
      return (
        <Paper sx={{width: '100%', padding: 2, boxShadow: 'none', height: '100px'}}>
          <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography variant="subtitle1" color={'text.subtitle'} fontWeight={'fontWeightRegular'} textAlign="center" lineHeight="1.25">
              No {type} transactions
            </Typography>
          </Box>
        </Paper>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rowsPerPage, page]);

  return mainComponent;
};

TransactionsList.propTypes = {
  data: PropTypes.array
};

const moneySchema = Yup.object().shape({
  amount: Yup.number().required('Amount is required').min(0, 'Amount must be greater than 0'),
  description: Yup.string().required('Description is required')
});

const transactionOptions = [
  {id: 'default', name: 'Select a transaction type'},
  {id: 'Bonus', name: 'Bonus'},
  {id: 'Rent Match', name: 'Rent Match'},
  {id: 'Stake Bonus', name: 'Stake Bonus'}
];

const SendMoneyDialog = ({isOpen, setOpen, resident}) => {
  const [sendMoney, {isSuccess}] = useCreateResidentTransactionsMutation();
  const methods = useForm({
    defaultValues: {
      amount: 0,
      description: ''
    },
    mode: 'all',
    resolver: yupResolver(moneySchema)
  });

  const {
    handleSubmit,
    formState: {isValid, isDirty},
    reset
  } = methods;

  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append('amount', data.amount);
    formData.append('description', data.description);
    sendMoney({id: resident?.mobile_user_id__c, dataForm: formData});
  };

  useEffect(() => {
    if (isSuccess) {
      reset({
        amount: 0,
        description: ''
      });
      setOpen(false);
    }
  }, [isSuccess]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitleWithCloseIcon onClose={handleClose} sx={{pt: 3}}>
        Send Money
      </DialogTitleWithCloseIcon>
      <DialogContent dividers sx={{'&.MuiDialogContent-root': {pb: 3, pr: 3, overflowY: 'hidden'}}}>
        <FormProvider {...methods}>
          <form style={{width: '100%'}} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" rowGap={1} alignItems="center" sx={{padding: 2}}>
              <Stack direction="row" columnGap={2} justifyContent="space-between" width="100%">
                <SimpleFormSelectWithLabel name="description" options={transactionOptions} />
                <FormAmount name="amount" />
              </Stack>
              <Stack direction="column" alignItems={'flex-end'} rowGap={1} sx={{width: '100%'}}>
                <Button type="submit" sx={{width: 'fit-content'}} variant="contained" color="secondary" disabled={!isDirty && !isValid}>
                  Send
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

SendMoneyDialog.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  resident: PropTypes.object
};

export const CurrentResidentOffers = ({offers}) => {
  if (offers && offers.length == 0) {
    return (
      <Paper sx={{width: '100%', padding: 2, boxShadow: 'none', height: '60px', borderRadius: '8px'}}>
        <Box sx={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Typography variant="subtitle1" color={'text.subtitle'} fontWeight={'fontWeightRegular'} textAlign="center" lineHeight="1.25">
            No Stake+ offers found.
          </Typography>
        </Box>
      </Paper>
    );
  } else {
    const columns = [...ResidentOffersColumns].map((column) => {
      const baseColumn = {
        ...column,
        field: column.id,
        headerName: column.label,
        renderCell: (params) => <TableBodyCell column={column} params={params} />,
        valueFormatter: (params) => valueGetter(params, column),
        renderEditCell: (params) => <TableBodyCell column={column} params={params} />
      };

      // Add special handling for the 'offers' column
      if (column.id === 'qualifiers') {
        return {
          ...baseColumn,
          sortComparator: offersComparator
        };
      }

      return baseColumn;
    });

    const rows = offers.map((offer) => {
      const performerId = getRandomValue();
      return {
        id: performerId,
        ...offer
      };
    });

    return (
      <Paper sx={{width: '100%', padding: 0, boxShadow: 'none', borderRadius: '8px'}}>
        <StyledTable
          rows={rows}
          density="compact"
          columns={columns}
          autoHeight
          hideFooter
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          getRowHeight={(row) => row.model?.qualifiers?.length * 30 || 40}
        />
      </Paper>
    );
  }
};
