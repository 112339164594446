import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {CircularProgress, Divider, Grid, Paper, Stack, Typography, useTheme} from '@mui/material';

import {getOpenItem} from '../../../../../app/store/reducers/menu';
import {getCampaigns, getCommunities, getResidents} from '../../../../../app/store/reducers/entities';
import {
  getDashboardAccount,
  getDashboardCampaign,
  getDashboardChildAccounts,
  getDashboardCommunity
} from '../../../../../app/store/reducers/dashboard';
import {getCampaignLoadingStatus, getCommunityLoadingStatus, getResidentLoadingStatus} from '../../../../../app/store/reducers/loading';
import {formatNumber} from '../../../../constants/stylingConstants';
import {hasApplied} from '../../../../constants/residentsInfoConstants';
import {ACTIVE_PROPERTY_STATUSES} from '../../../../constants/communityConstants';

const DataItem = ({title, value}) => {
  const theme = useTheme();
  return (
    <Stack direction="column" sx={{p: 2}}>
      <Typography variant="subtitle1" fontWeight="fontWeightRegular" color={theme.palette.text.subtitle}>
        {title}
      </Typography>
      <Typography variant="subtitle1" fontWeight="fontWeightRegular" color={theme.palette.text.primary}>
        {value}
      </Typography>
    </Stack>
  );
};

DataItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any
};

const DataSection = ({data}) => (
  <Grid container>
    {data.map((item, index) => (
      <React.Fragment key={index}>
        <Grid item xs={item.xs}>
          <DataItem title={item.title} value={item.value} />
        </Grid>
        {index < data.length - 1 && <Divider orientation="vertical" flexItem sx={{mr: '-1px'}} />}
      </React.Fragment>
    ))}
  </Grid>
);

DataSection.propTypes = {
  data: PropTypes.array
};

const DetailsContent = () => {
  const [generalDataContent, setGeneralDataContent] = useState([]);

  const openItem = useSelector(getOpenItem);
  const communities = useSelector(getCommunities);
  const campaigns = useSelector(getCampaigns);
  const residents = useSelector(getResidents);
  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const dashboardCampaign = useSelector(getDashboardCampaign);
  const residentsStatus = useSelector(getResidentLoadingStatus);
  const communitiesStatus = useSelector(getCommunityLoadingStatus);
  const campaignsStatus = useSelector(getCampaignLoadingStatus);
  const dashboardChildAccounts = useSelector(getDashboardChildAccounts);

  useEffect(() => {
    const findGeneralDataContent = () => {
      const loadingValue = <CircularProgress size={10} />;
      let contentData = [
        {title: 'Total units', value: loadingValue, xs: 4},
        {title: 'Total Stake units', value: loadingValue, xs: 4},
        {title: 'Stake+ campaigns', value: loadingValue, xs: 4}
      ];

      if (!openItem) return contentData;

      if (openItem === 'communities' && dashboardCommunity.sfid !== 'all') {
        // Community-specific calculations
        const selectedCommunity = communities.find((community) => community.sfid === dashboardCommunity.sfid);
        const totalUnits = selectedCommunity?.total_units__c || 0;

        const address = selectedCommunity?.full_address__street__s;
        const city = selectedCommunity?.full_address__city__s;
        const state = selectedCommunity?.full_address__statecode__s;
        const zipcode = selectedCommunity?.full_address__postalcode__s;
        const country = selectedCommunity?.full_address__countrycode__s;
        const fullAddress = `${address}, ${city}, ${state} ${zipcode}, ${country}`;
        const isOnbording = selectedCommunity?.property_status__c === 'Onboarding';

        contentData = [
          {title: 'Address', value: fullAddress, xs: 3},
          {title: 'Property status', value: selectedCommunity?.property_status__c, xs: 3}
        ];

        if (isOnbording) {
          contentData.push({title: 'Total units', value: totalUnits > 0 ? formatNumber(totalUnits) : 'Processing', xs: 3});
        } else {
          contentData.push({title: 'Total Stake units', value: totalUnits > 0 ? formatNumber(totalUnits) : 'No units', xs: 3});
        }

        const comCampaigns = campaigns.filter((campaign) => campaign.property_sfid === dashboardCommunity.sfid);
        const activeStakePlusCampaigns = comCampaigns.filter((campaign) => campaign.status == 'Active' && campaign.subtype != 'Stake1');

        contentData.push({
          title: 'Active Stake+ campaigns',
          value: activeStakePlusCampaigns.length > 0 ? formatNumber(activeStakePlusCampaigns.length) : 0,
          xs: 3
        });
      } else if (openItem === 'portfolio') {
        // Account-specific calculations
        const totalUnits = communities.reduce((acc, community) => acc + community.total_units__c, 0);
        const totalStakeUnits = communities
          .filter((c) => ACTIVE_PROPERTY_STATUSES.includes(c.property_status__c))
          .reduce((acc, community) => acc + community.total_units__c, 0);
        const isProcessing = dashboardAccount?.account_status__c && ['Demoing', 'Onboarding'].includes(dashboardAccount.account_status__c);

        contentData = [
          {title: 'Account status', value: dashboardAccount?.account_status__c, xs: 4},
          {
            title: 'Total units',
            value: !communitiesStatus.isLoading
              ? isProcessing && totalUnits == 0
                ? 'Processing'
                : formatNumber(totalUnits)
              : loadingValue,
            xs: 4
          },
          {
            title: 'Total Stake units',
            value: !communitiesStatus.isLoading
              ? isProcessing && totalStakeUnits == 0
                ? 'Processing'
                : formatNumber(totalStakeUnits)
              : loadingValue,
            xs: 4
          }
        ];
      }

      return contentData;
    };

    setGeneralDataContent(findGeneralDataContent());
  }, [
    openItem,
    communities,
    campaigns,
    residents,
    dashboardCampaign,
    dashboardCommunity,
    dashboardAccount,
    residentsStatus,
    communitiesStatus,
    campaignsStatus,
    dashboardChildAccounts
  ]);

  return (
    <Paper sx={{borderRadius: 2, boxShadow: 'none'}}>
      <Grid container sx={{minWidth: 300}}>
        {[generalDataContent].map((dataSection, sectionIndex) => (
          <React.Fragment key={sectionIndex}>
            {sectionIndex > 0 && openItem != 'portfolio' && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            <Grid item xs={12}>
              <DataSection data={dataSection} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Paper>
  );
};

export default DetailsContent;
