export const ResidentsColumns = [
    {
        id: 'name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'community',
        operator: 'and',
        numeric: false,
        disablePadding: false,
        label: 'Community',
        fieldType: 'chip',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'campaign',
        operator: 'and',
        numeric: false,
        disablePadding: false,
        label: 'Campaign',
        fieldType: 'chip',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'phone',
        numeric: false,
        disablePadding: true,
        label: 'Phone',
        fieldType: 'box',
        editable: false,
        dataFormat: 'phone',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'apartment_or_unit__c',
        numeric: false,
        disablePadding: true,
        label: 'Unit',
        fieldType: 'input',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1,
        maxWidth: 120
    },
    {
        id: 'floor_plan_name__c',
        numeric: false,
        disablePadding: true,
        label: 'Floor plan',
        fieldType: 'input',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1,
        minWidth: 120
    },
    {
        id: 'rent_amount__c',
        operator: 'and',
        numeric: true,
        disablePadding: true,
        label: 'Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1,
        maxWidth: 140
    },
    {
        id: 'amount_due__c',
        operator: 'and',
        numeric: true,
        disablePadding: true,
        label: 'Ledger balance',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1,
        maxWidth: 140
    },
    {
        id: 'lease_start_date__c',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Lease starts',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'lease_end_date__c',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Lease ends',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'offers',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Offers',
        fieldType: 'box',
        editable: false,
        dataFormat: 'grouping',
        icon: null,
        disableColumnFilter: true,
        filterable: false,
        disableColumnSorting: true,
        sortable: true,
        flex: 1,
        minWidth: 160
    },
    {
        id: 'this_months_payment',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Paid on time',
        fieldType: 'checkbox',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1,
        minWidth: 120
    }
];

export const ResidentsRenewalAppliedColumns = [
    {
        id: 'name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        minWidth: 200,
        flex: 1
    },
    // {
    //     id: 'property_name',
    //     operator: 'or',
    //     numeric: false,
    //     disablePadding: true,
    //     label: 'Property Name',
    //     fieldType: 'box',
    //     editable: false,
    //     dataFormat: 'default',
    //     color: null,
    //     icon: 'sort',
    //     disableColumnFilter: false,
    //     filterable: true,
    //     disableColumnSorting: false,
    //     sortable: true,
    //     flex: 1
    // },
    {
        id: 'lease_end_date__c',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Lease ends',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'rent',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'In Place Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'property_attained_rent',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: '120 Day Attained Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'attained_rent_delta',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Attained Rent Delta',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        colorValue: 0,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'market_rent',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Market Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'rent_delta',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Rent Delta',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        colorValue: 0,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];

export const CampaignsColumns = [
    {
        id: 'name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'community',
        operator: 'and',
        numeric: false,
        disablePadding: false,
        label: 'Community',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'subtype',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Type',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'status',
        operator: 'and',
        numeric: false,
        disablePadding: false,
        label: 'Status',
        fieldType: 'chip',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'all_actions',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Offers',
        fieldType: 'box',
        editable: false,
        dataFormat: 'grouping',
        icon: null,
        disableColumnFilter: true,
        filterable: false,
        disableColumnSorting: true,
        sortable: false,
        flex: 1
    },
    {
        id: 'target',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Targets',
        fieldType: 'box',
        editable: false,
        dataFormat: 'grouping',
        icon: null,
        disableColumnFilter: true,
        filterable: false,
        disableColumnSorting: true,
        sortable: false,
        flex: 1
    }
];

export const InvoiceColumns = [
    {
        id: 'contact_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1,
        minWidth: 150
    },
    {
        id: 'application_date',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Application date',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'lease_signed_date',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Lease signed date',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'lease_start_date',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Lease start date',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'move_in_date',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Move-in date',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'campaign_type',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Campaign',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'unit_number',
        operator: 'and',
        numeric: true,
        disablePadding: true,
        label: 'Unit number',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'rent_amount',
        operator: 'and',
        numeric: true,
        disablePadding: true,
        label: 'Rent amount',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'paid_this_month',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Paid on time',
        fieldType: 'checkbox',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'total_earned',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Cash Back earned Month to date',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        icon: null,
        disableColumnFilter: true,
        filterable: false,
        disableColumnSorting: true,
        sortable: false,
        flex: 1,
        minWidth: 150
    },
    {
        id: 'claimed_amount',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Claimed Cash Back',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        icon: null,
        disableColumnFilter: true,
        filterable: false,
        disableColumnSorting: true,
        sortable: false,
        flex: 1,
        maxWidth: 150
    },
    {
        id: 'offers',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Offers',
        fieldType: 'box',
        editable: false,
        dataFormat: 'grouping',
        icon: null,
        disableColumnFilter: true,
        filterable: false,
        disableColumnSorting: true,
        sortable: false,
        flex: 1,
        minWidth: 160
    }
];

export const TeamMembersColumns = [
    {
        id: 'name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'role',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Role',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'email',
        numeric: false,
        disablePadding: false,
        label: 'Email',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'permissions',
        operator: 'and',
        numeric: false,
        disablePadding: false,
        label: 'Community permissions',
        fieldType: 'chip',
        editable: false,
        dataFormat: 'grouping',
        icon: null,
        disableColumnFilter: true,
        filterable: false,
        disableColumnSorting: true,
        sortable: false,
        flex: 1
    }
];

export const GlanceConcessionColumns = [
    {
        id: 'property_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Property name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'current_average_market_concession',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Market Concession',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'current_average_stake_plus_offer',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Stake+ Offer',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'projected_savings',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Projected savings',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: 'red/green',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'total_units',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Total Units',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'us_state',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'State',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];

export const GlanceDaysOnMarketColumns = [
    {
        id: 'property_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Property name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'days_on_market',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Days On Market',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'days_on_market_comps',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Days On Market Comps',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'reduction',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Reduction',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: 'red/green',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'us_state',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'State',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];

export const GlanceDelinquencyColumns = [
    {
        id: 'property_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Property name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'non_stakers_delinquency_percent',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Delinquency without Stake',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'non_stakers',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Non-Stakers',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'stakers_delinquency_percent',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Delinquency with Stake',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'stakers',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Stake+ residents',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'reduction',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Reduction',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: 'red/green',
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'us_state',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'State',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];

export const ResidentPaymentHistoryColumns = [
    {
        id: 'payment_month',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Payment month',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'paid',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Paid',
        fieldType: 'checkbox',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'reported_to_credit_bureaus__c',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Reported to Credit Bureau',
        fieldType: 'checkbox',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];

export const ResidentOffersColumns = [
    {
        id: 'qualifiers',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Offer qualifiers',
        fieldType: 'list',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'reward_amount',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Reward amount',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];

export const vacancyCompColumns = [
    {
        id: 'building_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Property Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'bed',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Bed',
        fieldType: 'box',
        editable: false,
        dataFormat: 'numeric',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'bath',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Bath',
        fieldType: 'box',
        editable: false,
        dataFormat: 'numeric',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'sqft',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Sqft',
        fieldType: 'box',
        editable: false,
        dataFormat: 'numeric',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'price',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Price',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'floorplan_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Floor Plan',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'unit_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Unit Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'enter_market',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Enter Market',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'exit_market',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Exit Market',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'days_on_market',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Days On Market',
        fieldType: 'box',
        editable: false,
        dataFormat: 'numeric',
        color: 'red/green',
        colorValue: 45,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'availability',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Availability On',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'similarity',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Similarity',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];
export const VacancyGlanceColumns = [
    {
        id: 'property_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Property',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'unit_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Unit',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'floorplan_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Floor Plan',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'price',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'avg_comp_rent',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Market Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'rent_delta',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Rent Delta',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        colorValue: 0,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'enter_market',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Enter Market',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'availability',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Avalabile On',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'days_on_market',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Days On Market',
        fieldType: 'box',
        editable: false,
        dataFormat: 'numeric',
        color: 'red/green',
        colorValue: 45,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'avg_comp_days_on_market',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Market DOM',
        fieldType: 'box',
        editable: false,
        dataFormat: 'numeric',
        color: 'red/green',
        colorValue: 45,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'dom_delta',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'DOM Delta',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'last_update',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Last Updated',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];

export const ExposureCompColumns = [
    {
        id: 'property_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Property Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'resident_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Resident Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'unit_name',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Unit Name',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'lease_end_date',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Lease Ends',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'until_lease_end',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Lease Ends In',
        fieldType: 'box',
        editable: false,
        dataFormat: 'numeric',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'floor_plan',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Floor Plan',
        fieldType: 'box',
        editable: false,
        dataFormat: 'default',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'rent',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'In Place Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'property_attained_rent',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: '120 Day Attained Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'attained_rent_delta',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Attained Rent Delta',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        colorValue: 0,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'market_rent',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Market Rent',
        fieldType: 'box',
        editable: false,
        dataFormat: 'currency',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'rent_delta',
        operator: 'or',
        numeric: true,
        disablePadding: true,
        label: 'Rent Delta',
        fieldType: 'box',
        editable: false,
        dataFormat: 'percentage',
        color: null,
        colorValue: 0,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    },
    {
        id: 'effective_at',
        operator: 'or',
        numeric: false,
        disablePadding: true,
        label: 'Last Updated',
        fieldType: 'box',
        editable: false,
        dataFormat: 'date',
        color: null,
        icon: 'sort',
        disableColumnFilter: false,
        filterable: true,
        disableColumnSorting: false,
        sortable: true,
        flex: 1
    }
];

export const getRandomValue = () => {
    const crypto = window.crypto;
    const array = new Uint32Array(1);
    const rv = crypto.getRandomValues(array);
    return rv[0];
};

export const valueGetter = (params, column) => {
    if (column.id == 'all_actions' || column.id == 'offers') {
        const allValues = [];

        for (const offer of params) {
            allValues.push(offer.props.offer.offerRewardChipValue);
        }

        return allValues.join(', ');
    } else if (column.id == 'target') {
        if (params.length === 0) {
            return 'All targets';
        } else {
            return params.join(', ');
        }
    } else if (column.id == 'permissions') {
        const comPermissions = [...params].map((p) => p.props.children);
        const permissionsString = comPermissions.length > 0 ? comPermissions.join(', ') : 'All communities';
        return permissionsString;
    } else if (column.id == 'renter_action') {
        let textParams = params.split('<span style="font-weight: 500; color: #149CE0;">');
        textParams = textParams[1].split('</span>');
        textParams = textParams.join('');
        return textParams;
    } else {
        return params;
    }
};
