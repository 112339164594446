import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import {vacancyCompColumns, VacancyGlanceColumns, valueGetter} from '../../../../../constants/tableColumns';
import {StyledTable} from '../../../../@extended/CustomTables';

import TableBodyCell from '../../../../@extended/TableBodyCell';
import {CustomNoRowsOverlay, ExportToolbar, ExportToolBarWithSearch, ExportToolbarWithTitle} from '../../../../@extended/DataGridTables';
import {Box, Divider, Pagination, Stack, Typography, useTheme} from '@mui/material';
import {ExpandLessOutlined, ExpandMoreOutlined} from '@mui/icons-material';
import {sortNumbers, sortStrings} from '../../../../../utils/sorting';
import {useSelector} from 'react-redux';
import {getDashboardAccount} from '../../../../../../app/store/reducers/dashboard';
import { useGetVacancyCompsQuery } from '../../../../../../features/coreApi/metricsSlice';

const ComparisonPanel = ({ row }) => {
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  // Get the comps data from the row
  const buildingName = row?.property_name?.split(' ').join('-') || '';
  const unitName = row?.unit_name?.split(' ').join('-') || '';
  const fileName = `comps-${buildingName}-${unitName}`;

  // Use RTK Query hook
  const {
    data: compsData,
    isLoading,
    isFetching,
    error
  } = useGetVacancyCompsQuery(
    { 
      account_sfid: row?.account_sfid,
      property_sfid: row?.property_sfid,
      id: row?.PK.split('#')[2],
      params: { page, page_size: pageSize} 
    },
    {
      skip: !row?.PK || !row?.account_sfid || !row?.property_sfid,
      keepUnusedDataFor: 600,
    }
  );

  // Extract comps array and update totalPages
  const comps = useMemo(() => {
    if (compsData?.data && Array.isArray(compsData.data)) {
      if (compsData.pagination?.total_pages) {
        setTotalPages(compsData.pagination.total_pages);
      }
      return compsData.data;
    }
    return [];
  }, [compsData]);
  console.log(compsData)

  // Define columns for the comparison units table
  const compColumns = useMemo(() => {
    return [...vacancyCompColumns].map((column) => ({
      ...column,
      field: column.id,
      headerName: column.label,
      renderCell: (params) => <TableBodyCell column={column} params={params} />,
      renderEditCell: (params) => <TableBodyCell column={column} params={params} />,
      valueFormatter: (params) => valueGetter(params, column),
      sortComparator: column.numeric 
        ? (v1, v2) => sortNumbers(v1, v2) 
        : (v1, v2) => sortStrings(v1, v2)
    }));
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handlePaginationModelChange = (newModel) => {
    setPage(newModel.page);
    setPageSize(newModel.pageSize);
  };

  const isTableLoading = isLoading || isFetching;

  // Calculate total rows from pagination data
  const totalRows = compsData?.pagination?.total || 0;

  return (
    <Box sx={{p: 0, bgcolor: theme.palette.grey[100]}}>
      {error && (
        <Box sx={{ p: 2, color: 'error.main' }}>
          Error loading comparison data: {error.message || 'Unknown error'}
        </Box>
      )}
      
      <StyledTable
        columns={compColumns}
        rows={comps}
        autoHeight
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        rowHeight={40}
        loading={isTableLoading}

        pagination
        paginationMode="server"
        rowCount={totalRows}
        paginationModel={{
          page: page,
          pageSize: pageSize
        }}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[10, 20, 50, 100]}

        slots={{
          toolbar: () => ( 
            <ExportToolbarWithTitle 
              title={`Comparing to ${compsData?.pagination?.total || comps.length} similar units`} 
              borderColor="black" 
              defaultFilename={fileName} 
            />
          ),
          noRowsOverlay: () => (
            <CustomNoRowsOverlay 
              msg={isTableLoading 
                ? 'Loading...' 
                : error 
                  ? 'Error loading comparison data' 
                  : 'No comparable units found'} 
            />
          ),
        }}
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
            borderRadius: 0
          },
          '& .MuiDataGrid-cell': {
            bgcolor: theme.palette.grey[50],
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
            py: 1
          },
          '& .MuiDataGrid-columnHeaders': {
            bgcolor: theme.palette.grey[100],
            borderBottom: 'none'
          }
        }}
        getRowId={(row) => row.id || `${row.building_id}-${row.unit_name}`}
      />
    </Box>
  );
};

ComparisonPanel.propTypes = {
  row: PropTypes.object
};

const VacancyGlance = ({data}) => {
  const dashboardAccount = useSelector(getDashboardAccount);
  const fileName = dashboardAccount?.name ? `${dashboardAccount.name.replace(/ /g, '_')}_vacancy_glance_report` : 'vacancy_glance_report';
  const [expandedRowsSet, setExpandedRowsSet] = useState(new Set());

  const columns = useMemo(
    () => [
      ...VacancyGlanceColumns.map((column) => ({
        ...column,
        field: column.id,
        headerName: column.label,
        renderCell: (params) => <TableBodyCell column={column} params={params} />,
        renderEditCell: (params) => <TableBodyCell column={column} params={params} />,
        valueFormatter: (params) => valueGetter(params, column),
        sortComparator: column.numeric ? (v1, v2) => sortNumbers(v1, v2) : (v1, v2) => sortStrings(v1, v2)
      }))
    ],
    []
  );

  const getDetailPanelContent = (params) => {
    return <ComparisonPanel row={params.row} />;
  };

  return (
    <Stack direction={'column'} divider={<Divider />}>
      <Stack sx={{p: 2}}>
        <Typography variant={'subtitle2'} fontWeight={'fontWeightRegular'} color={'text.subtitle'}>
          All your units that we see available for rent on public listings sites. (&#39;DOM&#39; = Days on Market)
        </Typography>
      </Stack>
      <div style={{minHeight: 100, height: '100%', width: '100%'}}>
        <StyledTable
          columns={columns}
          rows={[...data]}
          onPaginationModelChange={() => {
            setExpandedRowsSet(new Set());
          }}
          pagination 
          pageSizeOptions={[10, 20, 50, 100]}
          slots={{
            toolbar: () => <ExportToolBarWithSearch defaultFilename={fileName} />,
            noRowsOverlay: () => <CustomNoRowsOverlay msg={'There is no vacant units'} />,
            detailPanelExpandIcon: ExpandMoreOutlined,
            detailPanelCollapseIcon: ExpandLessOutlined
          }}
          initialState={{
            pagination: {paginationModel: {pageSize: 10}}
          }}
          autoHeight
          density="standard"
          rowHeight={40}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          detailPanelExpandedRowIds={Array.from(expandedRowsSet)}
          onDetailPanelExpandedRowIdsChange={(ids) => {
            setExpandedRowsSet(new Set(ids));
          }}
          sx={{
            '& .MuiIconButton-root': {
              fontSize: '1.25rem'
            }
          }}
        />
      </div>
    </Stack>
  );
};

VacancyGlance.propTypes = {
  data: PropTypes.array
};

export default VacancyGlance;
