import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {Stack} from '@mui/material';

import {getCampaigns} from '../../../../../app/store/reducers/entities';
import {getDashboardCommunity} from '../../../../../app/store/reducers/dashboard';
import CampaignsTable from '../Campaign/CampaignsTable';
import DetailsContent from '../Portfolio/DetailsContent';
import FeedComponent from '../Feed/FeedComponent';
import Glance from '../Portfolio/Glance/Glance';

const CommunityTab = () => {
    const campaigns = useSelector(getCampaigns);
    const dashboardCommunity = useSelector(getDashboardCommunity);

    const communityCampaigns = useMemo(() => {
        return campaigns.filter((campaign) => campaign.property_sfid === dashboardCommunity.sfid);
    }, [dashboardCommunity, campaigns]);

    return (
        <Stack direction={'column'} rowGap={3} sx={{width: '100%'}}>
            <DetailsContent />
            <FeedComponent />
            <Glance />
            <CampaignsTable data={[...communityCampaigns]} />
        </Stack>
    );
};

export default CommunityTab;
