import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Divider, Stack, Typography, useTheme} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';

import {activeModal, getOpenItem} from '../../../../app/store/reducers/menu';
import {useLazyGetTeamMembersByAccountIdQuery} from '../../../../features/loyaltyCloudApi/apiSlice';
import {getDashboardAccount, getDashboardCommunity} from '../../../../app/store/reducers/dashboard';
import {ExportToolBarWithSearch} from '../../@extended/DataGridTables';
import {TeamMembersColumns, valueGetter} from '../../../constants/tableColumns';
import {getTeamMembers, setTeamMembers} from '../../../../app/store/reducers/entities';

import TableBodyCell from '../../@extended/TableBodyCell';
import {getIsStakeAdmin, getPropertyPermissions, getTeamMemberRole} from '../../../../app/store/reducers/session';
import {useLazyGetCommunitiesByAccountSfidQuery} from '../../../../features/coreApi/communitiesSlice';
import {useParams} from 'react-router-dom';

const TeamMembersTable = () => {
  const params = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const openItem = useSelector(getOpenItem);
  const dashboardAccount = useSelector(getDashboardAccount);
  const dashboardCommunity = useSelector(getDashboardCommunity);
  const selectedMembers = useSelector(getTeamMembers);
  const isStakeAdmin = useSelector(getIsStakeAdmin);
  const userRole = useSelector(getTeamMemberRole);
  const permissions = useSelector(getPropertyPermissions);

  const [fetchCommunities, {data: communities, isSuccess: isComSuccess, isLoading: isComLoading, isFetching: isComFetching}] =
    useLazyGetCommunitiesByAccountSfidQuery();

  useEffect(() => {
    if (params?.account_sfid) {
      fetchCommunities(params.account_sfid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.account_sfid]);

  const isAddOn = useMemo(() => {
    if (isStakeAdmin) {
      return true;
    }

    const isAdministrator = userRole == 'Administrator' || permissions.length == 0;
    return isAdministrator;
  }, [isStakeAdmin, userRole, permissions]);

  const [fetchTeamMembers, {data: teamMembers, isSuccess, isFetching, isLoading}] = useLazyGetTeamMembersByAccountIdQuery();

  useEffect(() => {
    if (dashboardAccount.sfid) {
      fetchTeamMembers(dashboardAccount.sfid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAccount]);

  useEffect(() => {
    if (!isLoading && !isFetching && isSuccess) {
      if (openItem == 'portfolio') {
        dispatch(setTeamMembers(teamMembers));
      } else if (openItem == 'communities' && dashboardCommunity.sfid && dashboardCommunity.sfid != 'all') {
        const fileteredTM = [];

        for (const member of teamMembers) {
          const perms = member.property_permissions.split(',').filter((p) => p);
          if (perms.includes(dashboardCommunity.sfid)) {
            fileteredTM.push(member);
          }
        }

        dispatch(setTeamMembers(fileteredTM));
      }
    }
  }, [openItem, isSuccess, isLoading, isFetching, dashboardAccount, dashboardCommunity]);

  const columns = useMemo(() => {
    let columns = [...TeamMembersColumns];

    return columns.map((column) => ({
      ...column,
      field: column.id,
      headerName: column.label,
      renderCell: (params) => <TableBodyCell column={column} params={params} />,
      renderEditCell: (params) => <TableBodyCell column={column} params={params} />,
      valueFormatter: (params) => valueGetter(params, column)
    }));
  }, []);

  const rows = useMemo(() => {
    if (selectedMembers.length > 0 && isComSuccess) {
      const resRows = selectedMembers.map((res) => {
        const obj = {
          id: res.id,
          permissions: []
        };

        TeamMembersColumns.forEach((column) => {
          const {id} = column;

          if (id == 'permissions') {
            const isAdministrator = res && (res?.role == 'Administrator' || res?.permissions.length == 0);

            if (isAdministrator) {
              obj[id] = [];
            } else {
              const comNames = res[id].map((p) => communities.find((c) => c.sfid == p)?.name || null);
              obj[id] = comNames
                .filter((c) => c)
                .map((c, index) => (
                  <Typography key={index} variant="body1">
                    {c}
                  </Typography>
                ));
            }
          } else {
            obj[id] = res[id];
          }
        });

        return obj;
      });

      return resRows;
    }
    return [];
  }, [selectedMembers, isComLoading, isComFetching, isComSuccess, communities]);

  const handleRowClick = (params) => {
    const {row} = params;
    dispatch(activeModal({type: 'team_member_info', sfid: row.id}));
  };

  const handleAdd = () => {
    dispatch(activeModal({type: 'new_team_member_info', sfid: null}));
  };

  return (
    <Stack direction={'column'} sx={{width: '100%', height: '100%'}} divider={<Divider />}>
      <Typography variant="h5" fontWeight={'fontWeightMedium'} sx={{pb: 2}}>
        Team
      </Typography>
      <DataGridPro
        rows={rows}
        columns={columns}
        loading={isLoading || isFetching}
        onRowClick={handleRowClick}
        slots={{
          toolbar: (props) => <ExportToolBarWithSearch {...props} isAddOn={isAddOn} handleAdd={handleAdd} />
        }}
        pagination
        pageSizeOptions={[10, 20, 50, 100]}
        initialState={{
          pagination: {paginationModel: {pageSize: 10}},
          sorting: {
            sortModel: [{field: 'status', sort: 'asc'}]
          }
        }}
        autoHeight
        rowHeight={60}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        sx={{
          // border: 'none',
          cursor: 'pointer',
          fontSize: '1rem',
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            fontSize: '0.875rem'
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '--DataGrid-rowBorderColor': theme.palette.grey[300],
          '.MuiTablePagination-toolbar': {
            overflow: 'hidden'
          },
          '.MuiDataGrid-footerContainer': {
            borderTop: `1px solid ${theme.palette.grey[300]}`
          }
        }}
      />
    </Stack>
  );
};

export default TeamMembersTable;
